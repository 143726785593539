
import Component from 'vue-class-component';
import { ICellRendererParams } from '@ag-grid-community/core';
import { AvailableRoutes } from '@/ui/types/route.types';
import { SlipType } from '@/modules/slip';
import BaseEventCell from './BaseEventCell';

interface EventSlipsButonProps extends ICellRendererParams {
  slipType?: SlipType;
  isSettlement: boolean;
  eventType: 'A' | 'P' | 'D'; // passed as url param on reports page
}

@Component
export default class EventSlipsButton extends BaseEventCell {
  declare params: EventSlipsButonProps | undefined;

  get buttonText() {
    return (this.$t('slip.slips') as string).toUpperCase();
  }

  openSlipsReport() {
    const routeData = this.$router.resolve({
      path: AvailableRoutes.slip,
      query: {
        event_id: this.event?.intKey.toString() || this.params?.data.intKey || '',
        slip_type: this.params?.slipType,
        event_type: this.params?.eventType,
        isFromSettlement: `${this.params?.isSettlement}`,
      },
    });
    window.open(routeData.href, '_blank');
  }
}
