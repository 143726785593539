
import Vue from 'vue';
import Component from 'vue-class-component';
import { ICellRendererParams } from '@ag-grid-community/core';

interface ReactiveCellProps extends ICellRendererParams {
  dataKey: string;
}

@Component
export default class ReactiveCell extends Vue {
  params: ReactiveCellProps | undefined;

  // Use this when u dont need any actions only reactive state in cell
  get hasData() {
    return !!this.params?.data;
  }

  get reactiveData() {
    return this.params?.data[this.params?.dataKey];
  }
}
