
import to from 'await-to-js';
import { Component, Prop, Vue } from 'vue-property-decorator';

import { getSportImage, getLocationImage } from '@/lib/image';
import { SettlementStore } from '@/modules/settlement/settlement.store';
import { SportStore } from '@/modules/sport';
import dayjs from 'dayjs';
import { FULL_DATE_TIME } from '@/constants';

@Component({ name: 'MissedEventsDropdown', components: {} })
export default class MissedEventsDropdown extends Vue {
  @Prop({ default: 'DUAL', required: false })
  eventType!: 'DUAL' | 'PLAYER';

  get isPlayer() {
    return this.eventType === 'PLAYER';
  }

  get missedEvents() {
    return this.isPlayer ? SettlementStore.missedPlayerEvents : SettlementStore.missedDualEvents;
  }

  get missedEventsCount() {
    return this.missedEvents.length;
  }

  get fullDateTime() {
    return FULL_DATE_TIME;
  }

  locationName(locationName: string) {
    if (!locationName) return '';
    return locationName;
  }

  successMessage(description: string) {
    return this.$notification.success({
      message: this.$t('common.success') as string,
      description: description,
    });
  }

  sportName(sportId: number) {
    if (sportId) return null;
    return SportStore.getSportById(sportId)?.name;
  }

  sportCode(sportId: number) {
    if (!sportId) return null;
    return SportStore.getSportById(sportId)?.code;
  }

  eventName(eventName: string) {
    if (!eventName) return '';
    return eventName;
  }

  playerName(playerName: string) {
    if (!playerName) return '';
    return playerName;
  }

  competitionName(competitionName: string) {
    if (!competitionName) return '';
    return competitionName;
  }

  initialTime(startTime: string) {
    return dayjs(startTime).format(FULL_DATE_TIME);
  }

  getSportImage(sportCode: string) {
    return getSportImage(sportCode);
  }

  getLocationImage(sportCode: string) {
    return getLocationImage(sportCode);
  }

  async onPick(eventId: string) {
    const [req, removeAction] = this.isPlayer
      ? [SettlementStore.assignPlayerEventToUser, SettlementStore.removeMissedPlayerEvent]
      : [SettlementStore.pickEvent, SettlementStore.removeMissedDualEvent];
    const [err] = await to<any>(req({ events: [eventId], assigneeId: null }));
    if (err) return;
    removeAction(eventId);
    this.$emit('assigned');
    this.successMessage(this.$t('settlement.pickSuccess') as string);
  }
}
