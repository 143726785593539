
import moment from 'moment';

import { WithDataTable } from '@/ui/mixins/WithDataTable';
import { Mixins, Component, Prop } from 'vue-property-decorator';
import { TableContainer } from '@/ui/components/TableContainer';
import ActionCell from '@/ui/components/CellRenderers/ActionCell.vue';
import { SettlementStore } from '@/modules/settlement/settlement.store';
import ReactiveCell from '@/ui/components/CellRenderers/ReactiveCell.vue';
import { EventDetailsSettlement } from '@/ui/components/EventDetailsSettlement';
import SettleOutcomeOdd from './SettleOutcomeOdd.vue';
import { FULL_DATE_TIME } from '@/constants';
import ResolveOddButton from '@/ui/components/CellRenderers/ResolveOddButton.vue';
import { settlementService } from '@/modules/settlement/settlement.service';
import to from 'await-to-js';
import { LocaleMessages } from 'vue-i18n';
import { objToArr } from '@/lib/objToArr';
import sortBy from 'lodash/sortBy';

@Component({
  name: 'SettleOutcomes',
  components: {
    TableContainer,
    ActionCell,
    ReactiveCell,
    ResolveOddButton,
    SettleOutcomeOdd,
    EventDetailsSettlement,
  },
})
export default class SettleOutcomes extends Mixins(WithDataTable) {
  fullDateTime = FULL_DATE_TIME;
  filterValue = [];
  outcomeSelectValue = '';

  @Prop({ default: 'DUAL', required: false })
  eventType!: 'DUAL' | 'PLAYER' | 'LIVE_PLAYER';

  get tableProps() {
    return {
      columnDefs: [
        {
          headerName: this.$t('settlement.type'),
          width: 80,
          field: 'stage',
        },
        {
          headerName: this.$t('settlement.marketName'),
          width: 160,
          field: 'market_name',
        },
        {
          headerName: this.$t('settlement.code'),
          width: this.isLivePlayer ? 275 : 75,
          field: 'outcome_name',
        },
        {
          headerName: this.$t('settlement.lost'),
          width: 65,
          cellRendererFramework: 'ResolveOddButton',
          cellRendererParams: {
            buttonText: this.$t('settlement.lost'),
          },
        },
        {
          headerName: this.$t('settlement.won'),
          width: 65,
          cellRendererFramework: 'ResolveOddButton',
          cellRendererParams: {
            buttonText: this.$t('settlement.won'),
          },
        },
        {
          headerName: this.$t('settlement.oddOne'),
          width: 65,
          cellRendererFramework: 'ResolveOddButton',
          cellRendererParams: {
            buttonText: this.$t('settlement.oddOne'),
            isOddOne: true,
          },
        },
        {
          headerName: this.$t('settlement.oddValue'),
          width: 85,
          cellRendererFramework: 'SettleOutcomeOdd',
        },
        {
          headerName: this.$t('settlement.limit'),
          width: 85,
          cellRendererFramework: 'ReactiveCell',
          cellRendererParams: {
            dataKey: 'limit',
          },
          field: 'limit',
        },
        {
          headerName: this.$t('settlement.reset'),
          width: 80,
          cellRendererFramework: 'ActionCell',
          cellRendererParams: {
            clickFunc: 'onResetOdd',
            buttonText: this.$t('settlement.reset'),
            buttonType: 'primary',
            eventType: this.eventType,
          },
        },
      ],
      rowData: this.tableData,
    };
  }

  get tableData() {
    return sortBy(objToArr(SettlementStore.activeOutcomes), outcome => outcome.outcome_id);
  }

  get eventId() {
    return SettlementStore.activeId;
  }

  get outcomes() {
    return this.tableData.map((outcome: any) => ({
      label: outcome.outcome_name,
      value: outcome.outcome_id,
    }));
  }

  get isSettleOddsDisabled() {
    return !SettlementStore.isSettleOddsPayloadValid || SettlementStore.activeEvent?.isVerified;
  }

  get isInfinite() {
    return false;
  }

  get gridOptions() {
    return {
      rowHeight: 58,
    };
  }

  get isLivePlayer() {
    return this.eventType === 'LIVE_PLAYER';
  }

  mounted() {
    setTimeout(() => this.gridApi?.hideOverlay());
  }

  close() {
    SettlementStore.clearActiveOutcomes();
    SettlementStore.clearSettleOddsPayload();
    SettlementStore.clearActiveEvent();
  }

  async refetchAllBets() {
    this.gridApi?.showLoadingOverlay();
    SettlementStore.clearOnlyOutcomes();
    SettlementStore.clearSettleOddsPayload();
    const req =
      this.eventType === 'DUAL'
        ? SettlementStore.getActiveOutcomes
        : SettlementStore.getActiveOutcomesPlayer;

    await to(req(this.eventId as string));
  }

  successMessage(description: LocaleMessages | string) {
    this.$notification.success({
      message: this.$t('common.success') as string,
      description: description as string,
    });
  }

  onTimeChange(value: any) {
    this.filterValue = value;
  }

  onOutcomeChange(value: any) {
    this.outcomeSelectValue = value;
  }

  onWonRest() {
    SettlementStore.bulkAddOutcomes('WON');
  }

  onLostRest() {
    SettlementStore.bulkAddOutcomes('LOST');
  }

  onOddOneRest() {
    SettlementStore.bulkAddOutcomes('ODD_ONE');
  }

  onClearAll() {
    SettlementStore.clearSettleOddsPayload();
  }

  async onOutcomeTimeOddOne() {
    // convert to UTC time and then send to BE
    const dateAfter = moment(this.filterValue[0])
      .utc(false)
      .format();
    const dateBefore = moment(this.filterValue[1])
      .utc(false)
      .format();
    const localDateAfter = this.filterValue[0];
    const localDateBefore = this.filterValue[1];
    const req =
      this.eventType === 'DUAL'
        ? settlementService.resetOddsByOutcomeAndTime
        : settlementService.resetPlayerOddsByOutcomeAndTime;
    const [err] = await to(
      req(this.eventId as string, {
        date_after: dateAfter,
        date_before: dateBefore,
        local_date_before: localDateBefore,
        local_date_after: localDateAfter,
        outcome_id: this.outcomeSelectValue,
      })
    );
    if (err) return;
    this.successMessage(this.$t('settlement.timedOutcomesOddOne'));
    this.refetchAllBets();
  }

  async onEventResetTimeOddOne() {
    // convert to UTC time and then send to BE
    const dateAfter = moment(this.filterValue[0])
      .utc(false)
      .format();
    const dateBefore = moment(this.filterValue[1])
      .utc(false)
      .format();
    const localDateAfter = this.filterValue[0];
    const localDateBefore = this.filterValue[1];
    const req =
      this.eventType === 'DUAL'
        ? settlementService.resetOddsByDateTime
        : settlementService.resetPlayerOddsByDateTime;
    const [err] = await to(
      req(this.eventId as string, {
        date_after: dateAfter,
        date_before: dateBefore,
        local_date_before: localDateBefore,
        local_date_after: localDateAfter,
      })
    );
    if (err) return;
    this.successMessage(this.$t('settlement.timedEventOddsOne'));
    this.refetchAllBets();
  }

  async onSettleOdds() {
    const req =
      this.eventType === 'DUAL' ? SettlementStore.settleOdds : SettlementStore.settlePlayerOdds;
    const [err] = await to(req());
    if (err) return;
    this.successMessage(this.$t('settlement.oddsSettledSuccess'));
  }
}
