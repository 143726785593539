
import to from 'await-to-js';
import { Prop, Component, Watch, Mixins } from 'vue-property-decorator';
import { SettlementStore } from '@/modules/settlement/settlement.store';
import { EventStore, PERIODS_BY_SPORT_MATCH_DATA, isLive } from '@/modules/event';
import isEmpty from 'lodash/isEmpty';
import { SettlementStopwatchStore } from '@/modules/settlementStopwatch/settlementStopwatch.store';
import { EventDetailsSettlement } from '@/ui/components/EventDetailsSettlement';
import { SportCode, SportStore } from '@/modules/sport';
import { NetworkStatus, WithNetworkStatus } from '@/ui/mixins/WithNetworkStatus';
import { EVENT_STATE } from '@/modules/event/event.constants';
import { FeedStore } from '@/modules/feed';

// RENAME TO MODAL
@Component({
  name: 'MatchData',
  components: {
    EventDetailsSettlement,
  },
})
export default class MatchData extends Mixins(WithNetworkStatus) {
  tempResults: any = [];
  tempPeriodResults: any = [];
  importantPeriodResults: any = [];
  tempImportantPeriodResults: any = [];

  @Prop({ type: Boolean })
  visible!: boolean;

  @Prop({ type: Function })
  close!: () => void;

  @Prop({ required: false, default: false })
  isLivePagePreview!: boolean;

  get activeEventId(): string {
    return (SettlementStore.activeId as string) ?? EventStore.matchDataEventId;
  }

  get resultHistory() {
    return SettlementStore.resultEntries;
  }

  get event() {
    return SettlementStore.activeEvent;
  }

  get periodResults() {
    return this.event?.periodResultJson;
  }

  get eventPeriod() {
    return SettlementStopwatchStore.getEventPeriod(Number(this.event?.id));
  }

  get eventMinute() {
    return SettlementStopwatchStore.getEventTime(Number(this.event?.id));
  }

  get sport() {
    return SportStore.getSportById(this.event.sportId);
  }

  get sportCode() {
    return this.sport?.code; //dynamic in future
  }

  get showPeriodResults() {
    return !this.isLivePagePreview;
  }

  get hasPeriodResults() {
    return !isEmpty(this.importantPeriodResults);
  }

  get isDisabled() {
    if (isLive(this.event)) {
      //@ts-ignore-next-line
      return this.event.state != EVENT_STATE.COMPLETED;
    }
    return false;
  }

  @Watch('visible')
  async onVisible(value: any) {
    if (value) {
      this.setNetworkStatus(NetworkStatus.InProgress);
      const [err, res]: any = await to(SettlementStore.getResultHistory(this.activeEventId as any));
      if (err || isEmpty(res?.settlement_history)) {
        this.setNetworkStatus(NetworkStatus.Error);
        return;
      }
      const entries = Object.keys(res?.settlement_history.periods);
      // @ts-ignore
      const sortedPeriods = PERIODS_BY_SPORT_MATCH_DATA[this.sportCode].filter((period: string) =>
        entries.includes(period)
      );
      this.importantPeriodResults = sortedPeriods.map((period: string) => {
        return {
          period: period.replace('p', ''),
          ...res.settlement_history.periods[period],
        };
      });
      this.tempImportantPeriodResults = this.importantPeriodResults;
      this.setNetworkStatus(NetworkStatus.Success);
    } else {
      SettlementStore.clearResultHistory();
      this.importantPeriodResults = [];
      this.tempImportantPeriodResults = [];
      this.setNetworkStatus(NetworkStatus.Success);
    }
  }

  //Create temp result history
  @Watch('resultHistory', { deep: true })
  onWatch(value: any) {
    if (value?.scores) {
      this.tempResults = [];
      Object.keys(this.resultHistory.scores).forEach((key: any) =>
        this.tempResults.push({
          home: this.resultHistory.scores[key].home,
          away: this.resultHistory.scores[key].away,
          minute: key,
        })
      );
    }
  }

  handleResultChangeHome(value: any, index: any) {
    this.tempResults[index].home = value.toString();
  }

  handleResultChangeAway(value: any, index: any) {
    this.tempResults[index].away = value.toString();
  }

  handlePeriodResultHome(value: any, index: any) {
    this.tempImportantPeriodResults[index].home = value.toString();
  }

  handlePeriodResultAway(value: any, index: any) {
    this.tempImportantPeriodResults[index].away = value.toString();
  }

  errorMessage(description: string = this.$t('genericError.description') as string) {
    return this.$notification.error({
      message: this.$t('common.error') as string,
      description: description,
    });
  }

  successMessage(description: any) {
    return this.$notification.success({
      message: this.$t('common.success') as string,
      description: description as string,
    });
  }

  getPeriodFromMinutes(minute: string | number) {
    const minToNum = parseInt(minute as string);
    if (minToNum === 0) return 'NS';
    if (minToNum <= 45) return 'FH';
    if (minToNum > 45 && minToNum <= 90) return 'SH';
    return 'OT';
  }

  handleMinuteChange(value: any, index: any) {
    if (value == null) return '';
    // this is done to get exact minute ->  example: 61:59 , to be shown as 62 minute
    const minutToString = (value - 1).toString();
    if (!minutToString) return '';
    this.tempResults[index].minute = minutToString + ':59';
  }

  formatMinutes(period: string) {
    if (this.sport?.code === SportCode.FOOTBALL) {
      const periodToTest = period.replace(':', '0');
      const formatPeriod = /^0+$/.test(periodToTest) ? parseInt(period) : parseInt(period) + 1;
      return formatPeriod.toString();
    } else {
      return period.split('::')[0];
    }
  }

  isFootball() {
    return this.sport?.code === SportCode.FOOTBALL;
  }

  formatPeriod(period: string) {
    if (period == '15' || period == '30') {
      return 'p' + period;
    }
    return period;
  }

  async editResult(index: any) {
    const [err] = await to(
      SettlementStopwatchStore.setResultFromMatchData({
        eventId: this.activeEventId as any,
        home: this.tempResults[index].home,
        away: this.tempResults[index].away,
        isFootball: this.sport?.code === SportCode.FOOTBALL,
        time: this.sport?.code === SportCode.FOOTBALL ? this.tempResults[index].minute : null,
        modalPeriod:
          this.sport?.code !== SportCode.FOOTBALL
            ? this.tempResults[index].minute
            : this.getPeriodFromMinutes(this.tempResults[index].minute),
      })
    );
    if (err) return;
    SettlementStore.setSettleBtnState({ eventId: this.activeEventId, isDisabled: false });
    this.successMessage(this.$t('settlement.resultSavedSuccess'));
  }

  async editPeriodResult(index: any) {
    const time = parseInt(this.tempImportantPeriodResults[index].period.match(/\d+/), 10) || null;
    const period = parseInt(this.tempImportantPeriodResults[index].period.match(/\d+/), 10)
      ? this.getPeriodFromMinutes(
          parseInt(this.tempImportantPeriodResults[index].period.match(/\d+/), 10)
        )
      : this.tempImportantPeriodResults[index].period;
    const [err] = await to(
      SettlementStopwatchStore.setResultPeriodResult({
        eventId: this.activeEventId as any,
        home: Number(this.tempImportantPeriodResults[index].home),
        away: Number(this.tempImportantPeriodResults[index].away),
        time: this.isFootball() ? time : null,
        period: this.isFootball() ? period : this.tempImportantPeriodResults[index].period,
      })
    );
    if (err) return;
    const [settleErr] = await to(
      SettlementStore.settleAll({
        eventId: this.activeEventId,
        params: {
          period: this.formatPeriod(this.tempImportantPeriodResults[index].period),
          resolving_by: 'period',
        },
      })
    );
    if (settleErr) return;
    if (time === 15) {
      SettlementStore.editEvent({ eventId: this.activeEventId, key: 'settled15', newValue: false });
    }
    if (time === 30) {
      SettlementStore.editEvent({ eventId: this.activeEventId, key: 'settled30', newValue: false });
    }
    this.successMessage(this.$t('settlement.resultSavedSuccess'));
    // SettlementStore.setSettleBtnState({ eventId: this.activeEventId, isDisabled: false });
  }

  async removeResult(index: any) {
    const [err] = await to(
      SettlementStore.clearResult({
        eventId: this.activeEventId as any,
        payload: { period: this.tempResults[index].minute },
      })
    );
    if (err) return;
    SettlementStore.getResultHistory(this.activeEventId as any);
    this.successMessage(this.$t('settlement.resultDeletedSuccess'));
  }

  async resetMatch() {
    const [err] = await to(SettlementStore.resetAllOdds(this.activeEventId));
    SettlementStore.resetResultLiveMatch({
      eventIntKey: this.activeEventId,
      sportCode: this.sportCode,
    });
    SettlementStopwatchStore.resetEventInStore(this.activeEventId);
    FeedStore.removeResultFeedEvent(this.activeEventId);
    if (err) return;
    this.close();
    this.successMessage(this.$t('settlement.matchResetSuccess'));
  }
}
