
import Vue from 'vue';
import Component from 'vue-class-component';
import { ICellRendererParams } from '@ag-grid-community/core';

import { SettlementStore } from '@/modules/settlement/settlement.store';

@Component
export default class SettleOutcomeOdd extends Vue {
  params: ICellRendererParams | undefined;

  // Use this when u dont need any actions only reactive state in cell
  get hasData() {
    return !!this.params?.data;
  }

  get oddValue() {
    return SettlementStore.activeOutcomes[this.params?.data.int_key].odd_value;
  }

  get betMadeAtResult() {
    return this.params?.data.score;
  }
}
