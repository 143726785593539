
import { to } from 'await-to-js';
import Component from 'vue-class-component';
import { SettlementStore } from '@/modules/settlement/settlement.store';
import BaseSettlementCell from './BaseSettlementCell';
import { TranslateResult } from 'vue-i18n';
import { ICellRendererParams } from '@ag-grid-community/core';
import { AuthStore } from '@/modules/auth';

interface PickUnpickPlayerEventParams extends ICellRendererParams {
  action: 'PICK' | 'UNPICK';
  onClick?: Function;
  pickedUnpicked?: (eventId: number) => number;
}

@Component
export default class PickUnpickPlayerEvent extends BaseSettlementCell {
  declare params: PickUnpickPlayerEventParams | undefined;

  get isDisabled() {
    if (!this.hasData) return true;
    return this.params?.data.assignee === AuthStore.userId && this.params?.action === 'PICK';
  }

  get playerEventId() {
    if (!this.hasData) return '';
    return this.params?.data.id;
  }

  get dualEventId() {
    if (!this.hasData) return '';
    return this.params?.data?.eventIntKey;
  }

  get isPick() {
    return this.params?.action === 'PICK';
  }

  get buttonLabel() {
    return this.isPick ? this.$t('settlement.pick') : this.$t('settlement.remove');
  }

  get pickSingleEventLabel() {
    return this.isPick ? this.$t('settlement.pickPlayer') : this.$t('settlement.removePlayer');
  }

  get pickDualDescendatsLabel() {
    return this.isPick ? this.$t('settlement.pickEvent') : this.$t('settlement.removeEvent');
  }

  successMessage(description: TranslateResult) {
    return this.$notification.success({
      message: this.$t('common.success') as string,
      description: description as string,
    });
  }

  errorMessage(description: TranslateResult = this.$t('genericError.description')) {
    return this.$notification.error({
      message: this.$t('common.error') as string,
      description: description as string,
    });
  }

  async pickPlayerEvent() {
    const [err] = await to(
      SettlementStore.assignPlayerEventToUser({ events: [this.playerEventId], assigneeId: null })
    );
    if (err) {
      return this.errorMessage();
    }
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    this.params?.pickedUnpicked!(this.playerEventId);
    return this.successMessage(this.$t('settlement.pickSuccess'));
  }

  async pickDualEventPlayerEvents() {
    const [err] = await to(
      SettlementStore.assignAllDualEventPlayerEventsToUser({
        eventsIds: [this.dualEventId],
        assigneeId: null,
      })
    );
    if (err) {
      return this.errorMessage();
    }
    return this.successMessage(this.$t('settlement.descendantsPickSuccess'));
  }

  async unpickPlayerEvent() {
    const [err] = await to(SettlementStore.deselectPlayerEvents([this.playerEventId]));
    if (err) return;
    // eslint-disable-next-line
    this.params?.pickedUnpicked!(this.playerEventId);
    return this.successMessage(this.$t('settlement.deselectSuccess'));
  }

  async unpickDualEventPlayerEvents() {
    const [err] = await to(SettlementStore.unassignAllDualEventPlayerEvents([this.dualEventId]));
    if (err) return;
    // eslint-disable-next-line
    this.params?.pickedUnpicked!(this.playerEventId);
    SettlementStore.setStateChanged();
    return this.successMessage(this.$t('settlement.descendantsDeselectSuccess'));
  }

  handleSingleEventAction() {
    const req = this.isPick ? this.pickPlayerEvent : this.unpickPlayerEvent;
    req();
  }

  handleAllDualEventDescendantsAction() {
    const req = this.isPick ? this.pickDualEventPlayerEvents : this.unpickDualEventPlayerEvents;
    req();
  }
}
