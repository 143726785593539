
import Vue from 'vue';
import to from 'await-to-js';
import { Component } from 'vue-property-decorator';
import { ICellRendererParams } from '@ag-grid-community/core';

import { SettlementStore } from '@/modules/settlement/settlement.store';
import { isLive } from '@/modules/event/event.types';
import { eventService } from '@/modules/event';
import { capitalizeFirstLetter } from '@/lib/capitalize';

interface PickXMLFeedParams extends ICellRendererParams {
  eventType: 'DUAL' | 'PLAYER';
}

@Component
export default class PickXMLFeed extends Vue {
  params: PickXMLFeedParams | undefined;

  get hasData() {
    return !!this.params?.data;
  }

  get eventId() {
    if (!this.params?.data) return '';
    return this.params?.data.id;
  }

  get event() {
    if (!this.hasData) return false;
    return this.params?.data;
  }

  get autoStatus() {
    if (!this.hasData || this.params?.eventType === 'PLAYER') return 'Off';
    if (this.event.isManualSettlement) return 'manual';
    if (this.sentFromLive && !this.params?.data.liveProvider && !this.params?.data.ctrlProvider)
      return 'Off';
    return false;
  }

  get defaultSelected() {
    if (this.autoStatus) return `${this.autoStatus}`;
    if (isLive(this.event)) return this.event.ctrlProvider || this.event.liveProvider;
    return this.event.ctrlProvider || 'off';
  }

  get sentFromLive() {
    return this.event.liveStatus === 'SETTLE';
  }

  get isDisabled() {
    if (this.event.isVerified) return true;
    if (!this.hasData || this.params?.eventType === 'PLAYER') return true;
    if (this.event.liveProvider) return true;
    return false;
  }

  get xmlFeeds() {
    const providers = eventService.mapProvidersFromStruct(this.event.ctrlProviders);
    providers.push({
      label: 'Off',
      value: 'off',
      key: Math.random(),
      class: 'off-select',
    });
    providers.push({
      label: 'Manual',
      value: 'manual',
      key: Math.random(),
      class: 'manual-select',
    });
    return providers;
  }

  async onProviderChange(provider: string) {
    const payload = {
      provider: {
        settlement_provider: provider,
      },
      intKey: this.event.intKey,
    };
    const [err] = await to(SettlementStore.changeProvider(payload));
    if (err) return;
    this.$notification.success({
      message: this.$t('common.success') as string,
      description: this.$t('settlement.autoSettleSuccess') as string,
    });
  }

  capitalizeFirstLetter(value: string) {
    return capitalizeFirstLetter(value);
  }
}
