import { render, staticRenderFns } from "./PlayerSettleByMarket.vue?vue&type=template&id=70b272b2&"
import script from "./PlayerSettleByMarket.vue?vue&type=script&lang=ts&"
export * from "./PlayerSettleByMarket.vue?vue&type=script&lang=ts&"
import style0 from "./PlayerSettleByMarket.vue?vue&type=style&index=0&lang=less&module=true&"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports