
import Vue from 'vue';
import { to } from 'await-to-js';
import Component from 'vue-class-component';
import { ICellRendererParams } from '@ag-grid-community/core';
import { SettlementStore } from '@/modules/settlement/settlement.store';
import { SettlementAntepostStore } from '@/modules/settlementAntepost';
import { settlementService } from '@/modules/settlement/settlement.service';
import { TranslateResult } from 'vue-i18n';
import { SettlementStopwatchStore } from '@/modules/settlementStopwatch/settlementStopwatch.store';
import { eventService, EVENT_STATE } from '@/modules/event/';
import { CommonSportPeriods } from '@/modules/settlement/settlement.types';

interface ActionCellParams extends ICellRendererParams {
  onClick?: (arg?: any) => any;
  updateEvent: (eventId?: number) => number;
  buttonText: string;
  buttonType: 'primary' | 'danger';
  clickFunc?: string;
  disabledCondition: any;
  disabledComparatorKey: string;
  eventType: 'PLAYER' | 'DUAL' | 'LIVE_PLAYER';
  showPopconfirm: boolean;
}

@Component
export default class ActionCell extends Vue {
  params: ActionCellParams | undefined;

  get hasData() {
    return !!this.params?.onClick || !!this.params?.clickFunc;
  }

  get event() {
    return this.params?.data;
  }

  get eventPeriod() {
    return SettlementStopwatchStore.getEventPeriod(this.eventId);
  }

  //These 2 getters are switch, since its prettier if theres more cases
  //Which i expect them to be
  get isDisabled() {
    if (!this.params?.data) return true;
    switch (this.params?.clickFunc) {
      case 'onPick':
        if (this.params?.data.assignee === this.params.disabledCondition) return true;
        break;
      case 'onVerify':
        if (
          this.event.state === 'COMPLETED' &&
          !this.params.data.isVerified &&
          this.event.ctrlProvider
        )
          return false;
        if (
          (!['FT', 'OT'].includes(this.eventPeriod) && !this.isPlayerEvent) ||
          this.params.data.isVerified
        ) {
          return true;
        }
        break;
    }
    return false;
  }

  get buttonTypeModifier() {
    if (!this.params?.data) return this.params?.buttonType;
    switch (this.params?.clickFunc) {
      // Deprecated since no teletext
      // case 'onToggleTTX':
      //   if (!this.params?.data.is_ttx) return 'danger';
      //   break;
      case 'onAutoSettlement':
        if (!this.params?.data.automatic_settlement_only) return 'danger';
        break;
      case 'onSetOddToOne':
        if (this.params?.data.odd_value === 1) return 'primary';
        break;
    }
    return this.params?.buttonType;
  }

  get isPlayerEvent() {
    return this.params?.eventType === 'PLAYER' ? true : false;
  }

  get buttonTextModifier() {
    return this.params?.buttonText;
  }

  get eventId() {
    if (!this.params?.data) return '';
    return this.params?.data.id;
  }

  get activeEventId() {
    return SettlementStore.activeId;
  }

  get antepostEventId() {
    return SettlementAntepostStore.activeId;
  }

  get showPopconfirm() {
    if (this.params?.showPopconfirm) return true;
    return false;
  }

  getAccesor = (intKey: number | string) => eventService.formatEventId(intKey);

  errorMessage(description: TranslateResult = this.$t('genericError.description')) {
    return this.$notification.error({
      message: this.$t('common.error') as string,
      description: description as string,
    });
  }

  successMessage(description: TranslateResult) {
    return this.$notification.success({
      message: this.$t('common.success') as string,
      description: description as string,
    });
  }

  onOpenNote() {
    SettlementStore.setActiveEvent(this.eventId);
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    this.params?.onClick!();
  }

  async onPick() {
    const [err] = await to<any>(
      SettlementStore.pickEvent({ events: [this.eventId], assigneeId: null })
    );
    if (err) return this.errorMessage();
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    this.params?.updateEvent(this.eventId);
    this.successMessage(this.$t('settlement.pickSuccess'));
  }

  // Deprecated - NO TTX FOR NOW
  // async onToggleTTX() {
  //   const req = this.isPlayerEvent ? SettlementStore.toggleTTXPlayers : SettlementStore.toggleTTX;
  //   const [err] = await to<any>(
  //     req({ eventIds: [this.eventId], nextValue: !this.params?.data.is_ttx })
  //   );
  //   if (err) return this.errorMessage();
  //   this.successMessage(this.$t('settlement.ttxSuccess'));
  // }

  async onSetAllOddsToOne() {
    const req = this.isPlayerEvent
      ? settlementService.setAllPlayerOddsToOne
      : settlementService.setAllOddsToOne;
    const eventType = this.params?.data.isLiveBooking ? 'live' : 'prematch';
    const [err] = await to<any>(req(this.eventId, eventType));
    if (err) return this.errorMessage();
    SettlementStore.updateEventWithoutRender({
      eventId: this.eventId,
      key: 'isVerified',
      newValue: true,
    });
    SettlementStore.editEvent({
      eventId: this.eventId,
      key: 'state',
      newValue: EVENT_STATE.COMPLETED,
    });
    this.successMessage(this.$t('settlement.setAllToOneSuccess'));
  }

  async onPostponed() {
    const [err] = await to(
      SettlementStore.setPostponed({
        eventId: this.eventId,
        settlementNote: '\nODLOZENO\n',
      })
    );
    if (err) return this.errorMessage();
    this.params?.updateEvent(this.eventId);
    this.successMessage(this.$t('settlement.postponedSuccess'));
  }

  async onStopped() {
    const [err] = await to(SettlementStore.setEventTerminated(this.eventId));
    if (err) return this.errorMessage();
    this.successMessage(this.$t('settlement.terminatedSuccess'));
  }

  async onMatchBets() {
    let req: Function;
    switch (this.params?.eventType) {
      case 'PLAYER':
        req = SettlementStore.getActiveOutcomesPlayer;
        break;
      case 'LIVE_PLAYER':
        req = SettlementStore.getActiveOutcomesLivePlayer;
        break;
      default:
        req = SettlementStore.getActiveOutcomes;
    }
    const [_err, res] = await to(req(this.eventId));
    if (res === 0) {
      return this.successMessage(this.$t('settlement.noBetsForSelectedEvent'));
    }
    SettlementStore.setActiveEvent(this.eventId);
  }

  async onVerify() {
    const req = this.isPlayerEvent
      ? SettlementStore.verifyPlayerSettlement
      : SettlementStore.verifyEvent;
    const [err] = await to<any>(req(this.eventId));
    if (err) return this.errorMessage();
    SettlementStore.setSettleBtnState({ eventId: this.eventId, isDisabled: true });
    SettlementStopwatchStore.resetResultMapEvent(this.eventId);
    SettlementStore.updateEventWithoutRender({
      eventId: this.eventId,
      key: 'currentPeriod',
      newValue: CommonSportPeriods.FULL_TIME,
    });
    this.successMessage(this.$t('settlement.verifySuccess'));
  }

  async onDeselect() {
    const [err] = await to<any>(SettlementStore.deselectEvent([this.eventId]));
    if (err) return this.errorMessage();
    this.params?.updateEvent();
    this.successMessage(this.$t('settlement.deselectSuccess'));
  }

  async onResetOdd() {
    const req = this.isPlayerEvent ? SettlementStore.resetPlayerOdd : SettlementStore.resetOdd;
    const [err] = await to(
      req({ oddId: this.params?.data.odd_id, eventId: Number(this.activeEventId) })
    );
    if (err) return;
    SettlementStore.resetOddSettlement({
      oddId: this.params?.data.odd_id,
    });
    this.successMessage(this.$t('settlement.oddResetSuccess'));
  }

  async onResetOddAntepost() {
    const req = SettlementAntepostStore.resetOdd;
    const [err] = await to(req(this.params?.data.intKey));
    if (err) return;
    SettlementAntepostStore.resetAntepostOdd({
      oddId: this.params?.data.intKey,
    });
    SettlementAntepostStore.refetchPlayedOutcomes(this.antepostEventId as number);
    this.successMessage(this.$t('settlement.oddResetSuccess'));
  }

  onClickHandler(event: any) {
    if (event) {
      event.stopPropagation();
    }

    if (this.params?.clickFunc) {
      (this as any)[this.params.clickFunc]();
    } else {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      this.params?.onClick!(this.params?.data);
    }
  }

  // ANTEPOST SETTLEMENT ACTIONS
  setSelectedAntepost() {
    SettlementAntepostStore.setSelectedAntepost(this.params?.data.intKey);
  }

  async resetAllBets() {
    const req = SettlementAntepostStore.resetAllBets;
    const [err] = await to(req(this.params?.data.intKey));
    if (err) return;
    SettlementAntepostStore.refetchPlayedOutcomes(this.params?.data.intKey);
    this.successMessage(this.$t('settlement.oddResetSuccess'));
  }
}
