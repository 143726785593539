
import Vue from 'vue';
import Component from 'vue-class-component';
import { ICellRendererParams } from '@ag-grid-community/core';
import { SportCode, SportStore } from '@/modules/sport';
import { SettlementStopwatchStore } from '@/modules/settlementStopwatch/settlementStopwatch.store';
import { SETTLEMENT_PERIODS_BY_SPORT } from '@/modules/settlementStopwatch/settlementStopwatch.constants';
import { eventService } from '@/modules/event';

@Component
export default class ChangePeriodCell extends Vue {
  params: ICellRendererParams | undefined;

  get hasData() {
    return !!this.params?.data;
  }

  get sport() {
    if (!this.hasData) return null;
    return SportStore.getSportById(this.params?.data.sportId);
  }

  mounted() {
    if (!this.eventPeriod) {
      SettlementStopwatchStore.setEventPeriod({ eventId: this.params?.data.id, period: 'NS' });
    }
  }

  get periods() {
    return SETTLEMENT_PERIODS_BY_SPORT[this.sport?.code];
  }

  get eventId() {
    if (!this.hasData) return '';
    return this.params?.data.id;
  }

  get eventPeriod() {
    return (
      this.eventTempChanges?.period ||
      SettlementStopwatchStore.periodMap[eventService.formatEventId(this.eventId)]
    );
  }

  get eventTempChanges() {
    return SettlementStopwatchStore.getEventTempChanges(this.eventId);
  }

  get isChangeDisabled() {
    if (!this.hasData) return true;
    return !this.params?.data.isManualSettlement || this.params?.data.isVerified;
  }

  isLiveBooking() {
    return this.params?.data.isLiveBooking;
  }

  errorMessage(description: string = this.$t('genericError.description') as string) {
    return this.$notification.error({
      message: this.$t('common.error') as string,
      description: description,
    });
  }

  successMessage(description: string) {
    return this.$notification.success({
      message: this.$t('common.success') as string,
      description: description,
    });
  }

  onClickHandler() {
    if (this.sport?.code === SportCode.FOOTBALL) {
      const nextMinute = this.periods?.[this.eventPeriod].nextMinute;
      SettlementStopwatchStore.setEventTempChanges({
        eventId: this.params?.data.id,
        newData: { minute: nextMinute },
      });
    }
    const nextPeriod = this.periods?.[this.eventPeriod].next;
    SettlementStopwatchStore.setEventTempChanges({
      eventId: this.params?.data.id,
      newData: { period: nextPeriod },
    });
  }
}
