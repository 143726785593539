/* eslint-disable no-useless-catch */
import moment from 'moment';
import { Mixins, Component, Watch } from 'vue-property-decorator';
import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';

import { FilterConfig } from '@/modules/filter/filter.types';
import { DATE } from '@/constants';
import EventLiveResults from '@/ui/components/CellRenderers/EventLiveResults.vue';
import ActionCell from '@/ui/components/CellRenderers/ActionCell.vue';
import { SettlementStore } from '@/modules/settlement/settlement.store';
import { AuthStore } from '@/modules/auth';
import ReactiveCell from '@/ui/components/CellRenderers/ReactiveCell.vue';
import SettleOutcomes from '@/ui/pages/settlement/components/SettleOutcomes.vue';
import MatchData from '@/ui/pages/settlement/components/MatchData.vue';
import SettlementStopwatch from '@/ui/components/CellRenderers/SettlementStopwatch.vue';
import ChangePeriodCell from '@/ui/components/CellRenderers/ChangePeriodCell.vue';
import { ASSIGNEE_SELECT_OPTIONS } from '@/modules/settlement/settlement.constants';
import SettlementResults from '@/ui/components/CellRenderers/SettlementResults.vue';
import { FilterStore } from '@/modules/filter/filter.store';
import CheckboxCell from '@/ui/components/CellRenderers/CheckboxCell.vue';
import SettlementNotesModal from '@/ui/pages/settlement/components/SettlementNotesModal.vue';
import { FULL_DATE_TIME } from '@/constants';
import PublishUnpublishButton from '@/ui/components/CellRenderers/PublishUnpublishButton.vue';
import { EventStatusLegend } from '@/ui/components/EventStatusLegend';
import MissedEventsDropdown from '@/ui/pages/settlement/components/MissedEventsDropdown.vue';
import PickXMLFeed from '@/ui/components/CellRenderers/PickXMLFeed.vue';
import EventSlipsButton from '@/ui/components/CellRenderers/EventSlipsButton.vue';
import { WithDataTable } from '@/ui/mixins/WithDataTable';
import { WithFilters } from '@/ui/mixins/WithFilters';
import { SportFilterStore } from '@/modules/sportFilter/sportFilter.store';
import { CommonStore } from '@/modules/common';
import { SportFilter } from '@/ui/components/SportFilter';
import { TableContainer } from '@/ui/components/TableContainer';
import { playerService } from '@/modules/player';
import { FeedStore } from '@/modules/feed';
import { SportStore } from '@/modules/sport';
import { SettlementStopwatchStore } from '@/modules/settlementStopwatch/settlementStopwatch.store';
import SettlementEventName from '@/ui/components/CellRenderers/SettlementEventName.vue';
import { eventService } from '@/modules/event/event.service';

@Component({
  components: {
    ActionCell,
    PickXMLFeed,
    ReactiveCell,
    CheckboxCell,
    SettleOutcomes,
    MatchData,
    EventLiveResults,
    EventSlipsButton,
    ChangePeriodCell,
    SettlementResults,
    EventStatusLegend,
    SettlementEventName,
    SettlementStopwatch,
    MissedEventsDropdown,
    SettlementNotesModal,
    PublishUnpublishButton,
    TableContainer,
    SportFilter,
  },
})
export default class WithSettlement extends Mixins(WithDataTable, WithFilters) {
  onChangeDebounced = debounce(this.refetchDataHandler, 1000);

  filterConfig: FilterConfig = [
    { label: this.$t('event.name'), type: 'input-filter', key: 'name' },
    {
      label: this.$t('settlement.isAssigned'),
      type: 'select-filter',
      key: 'is_assigned',
      props: {
        selectOptions: ASSIGNEE_SELECT_OPTIONS,
      },
    },
    {
      label: this.$t('settlement.unsettled'),
      type: 'checkbox-filter',
      key: 'hasUnsettled',
    },
    {
      label: this.$t('common.start'),
      type: 'range-filter',
      key: ['startDate', 'endDate'],
      props: { showTime: true },
    },
  ];

  // We have 2 types of table on every settlement page
  // one is for basic view that has no actions expect pick (BASE TABLE)
  // the other one is for all actions that you can call when u assign certain event to yourself
  get OPERATOR_COLUMNS(): any {
    return [];
  }

  get BASE_COLUMNS(): any {
    return [];
  }

  get OPERATOR_TABLE_PROPS() {
    return {
      columnDefs: this.OPERATOR_COLUMNS,
      suppressRowClickSelection: true,
      onRowClicked: this.onRightClick,
      cacheBlockSize: 50,
      rowBuffer: 50,
      onCellContextMenu: this.onRightClick,
      rowData: this.tableData,
    };
  }

  get BASE_TABLE_PROPS() {
    return {
      columnDefs: this.BASE_COLUMNS,
      rowMultiSelectWithClick: true,
      suppressRowClickSelection: true,
      rowSelection: 'multiple',
      onRowClick: this.onRowSelect,
      cacheBlockSize: 50,
      rowBuffer: 50,
      rowData: this.tableData,
    };
  }

  get UNSETTLED_BASE_TABLE_PROPS() {
    return {
      columnDefs: this.BASE_COLUMNS,

      rowMultiSelectWithClick: true,
      suppressRowClickSelection: true,
      rowSelection: 'multiple',
      onRowClick: this.onRowSelect,
      cacheBlockSize: 50,
      rowBuffer: 50,
      rowData: this.tableData,
    };
  }

  get UNSETTLED_OPERATOR_TABLE_PROPS() {
    return {
      columnDefs: this.BASE_COLUMNS,
      rowMultiSelectWithClick: true,

      suppressRowClickSelection: true,
      rowSelection: 'multiple',
      onRowClick: this.onRowSelect,
      cacheBlockSize: 50,
      rowBuffer: 50,
      rowData: this.tableData,
    };
  }

  get tableProps() {
    if (this.isOperatorTable && this.activeFilters.hasUnsettled) {
      return this.UNSETTLED_OPERATOR_TABLE_PROPS;
    }
    if (this.isOperatorTable && !this.activeFilters.hasUnsettled) {
      return this.OPERATOR_TABLE_PROPS;
    }
    if (!this.isOperatorTable && this.activeFilters.hasUnsettled) {
      return this.UNSETTLED_BASE_TABLE_PROPS;
    }
    return this.BASE_TABLE_PROPS;
  }

  get isOperatorTable() {
    return this.activeFilters?.is_assigned === 'assignedToMe';
  }

  get currentUser() {
    return AuthStore.userId;
  }

  get events() {
    return SettlementStore.forSettlement;
  }

  get usersForAssigneDropdown() {
    return SettlementStore.usersForAssigneDropdown;
  }

  get totalRecords() {
    return SettlementStore.totalSettlement;
  }

  get hasSettleResults() {
    return SettlementStore.settleResults.length > 0;
  }

  get hasActiveOutcomes() {
    return SettlementStore.totalActiveOutcomes > 0;
  }

  get activeEventId() {
    return SettlementStore.activeId;
  }

  get selectedCompetitions() {
    return SportFilterStore.parsedSelectedCompetitions;
  }

  get modalVisible() {
    return CommonStore.modalVisible;
  }

  get canFetchData() {
    return !!SportFilterStore.sports;
  }

  get gridOptions() {
    return {
      rowHeight: 58,
      getRowClass: ({ data }: any) => playerService.getPlayerEventClass(data),
    };
  }

  get addAssignedEventToUserIntKey() {
    return SettlementStore.addAssignedEventToUserIntKey;
  }

  @Watch('addAssignedEventToUserIntKey')
  assignEventToUser(intKey: string) {
    const eventAccessor = eventService.formatEventId(intKey.split('_')[0]);
    const event = SettlementStore.settlementEvents[eventAccessor];

    // compare filter state
    let isFilterLocationMatching = true;
    let isTopFilterMatching = false;
    let isTheSameDay = true;
    // is userId is matching assignee id
    const isCurrentUser = event.assignee === AuthStore.userId;
    if ('startDate' in this.activeFilters)
      isTheSameDay = moment(moment(event.start).format(DATE)).isSame(this.activeFilters.startDate);

    if ('is_assigned' in this.activeFilters) {
      isTopFilterMatching =
        this.activeFilters.is_assigned === 'assigned' ||
        this.activeFilters.is_assigned === 'assignedToMe';
    }
    if (!isEmpty(SportFilterStore.selectedLocations)) {
      isFilterLocationMatching = SportFilterStore.selectedLocations[event.sportId]?.includes(
        event.locationId
      );
    }
    if (isTheSameDay && isFilterLocationMatching && isTopFilterMatching && isCurrentUser) {
      const foundEvent = this.gridApi?.getRowNode(`${event.intKey}`);
      if (foundEvent) return;
      this.gridApi?.applyTransaction({ add: [event] });
    }
  }

  sortUpdated(_sort: string[]) {
    SettlementStore.resetStore();
  }

  refetchData() {
    SettlementStore.resetStore();
    this.resetTable();
    this.gridApi?.showLoadingOverlay();
    this.eventsRequest();
  }

  mounted() {
    const thisDay = moment().startOf('day');
    const today = thisDay.format(FULL_DATE_TIME);
    const tommorow = thisDay.add(1, 'days').format(FULL_DATE_TIME);
    FeedStore.init();
    SportStore.fetchSports();
    FilterStore.init(this.filterConfig);
    FilterStore.onValueChange({
      key: ['startDate', 'endDate'],
      value: [today, tommorow],
    });
    try {
      this.refetchData();
    } catch (error) {
      throw error;
    }
    SettlementStopwatchStore.initStore();
  }

  destroyed() {
    FeedStore.disconect();
    SettlementStore.resetStore();
  }

  get eventsRequest() {
    return SettlementStore.getSettlementEvents;
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onRowSelect() {}
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onRightClick(_e: any) {}
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  clearSelectedEvents() {}

  errorMessage(description: string = this.$t('genericError.description') as string) {
    return this.$notification.error({
      message: this.$t('common.error') as string,
      description: description,
    });
  }

  successMessage(description: string) {
    return this.$notification.success({
      message: this.$t('common.success') as string,
      description: description,
    });
  }

  get toggleModal() {
    return (modal: any) => CommonStore.toggleModal(modal);
  }

  refetchDataHandler() {
    if (!this.gridApi) {
      setTimeout(() => {
        this.refetchData();
      }, 1000);
      return;
    }
    this.refetchData();
  }

  onMissedEventAssigned() {
    if (!this.activeFilters?.is_assigned) return;
    this.refetchData();
  }
}
