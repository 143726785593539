
import Vue from 'vue';
import { Component } from 'vue-property-decorator';

import { EventTime } from '@/ui/components/EventTime';
import { SettlementStore } from '@/modules/settlement/settlement.store';
import { SportStore } from '@/modules/sport';

@Component({ components: { EventTime } })
export default class EventDetailsSettlement extends Vue {
  get sport() {
    if (!this.selectedEvent) return null;
    return SportStore.getSportById(this.selectedEvent.sportId);
  }

  get selectedEvent(): any {
    return SettlementStore.activeEvent;
  }

  get competitionName() {
    if (!this.selectedEvent) return '';
    return this.selectedEvent.competition_name || this.selectedEvent.competitionName;
  }

  get sportName() {
    if (!this.selectedEvent) return '';
    return this.sport?.name;
  }

  get locationName() {
    if (!this.selectedEvent) return '';
    return this.selectedEvent.location_name || this.selectedEvent.locationName;
  }

  get eventName() {
    if (!this.selectedEvent) return '';
    return this.selectedEvent?.name;
  }

  get playerName() {
    if (!this.selectedEvent) return '';
    return this.selectedEvent.playerName;
  }

  close() {
    this.$emit('close');
  }
}
