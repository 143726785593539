
import { Component } from 'vue-property-decorator';

import { getSportImage, getLocationImage } from '@/lib/image';
import BaseEventCell from './BaseEventCell';
import { EventTime } from '@/ui/components/EventTime';
import { SuspendAction } from '@/ui/components/SuspendAction';
import { SportStore } from '@/modules/sport';
import { SettlementEvent } from '@/modules/settlement/settlement.types';
import { ICellRendererParams } from '@ag-grid-community/core';
import to from 'await-to-js';
import { EventStore } from '@/modules/event';
import { SettlementStore } from '@/modules/settlement/settlement.store';

interface SettlementParams extends ICellRendererParams {
  data: SettlementEvent;
  showSettlementNote: boolean;
  showSetTimeNow: boolean;
}

@Component({ components: { EventTime, SuspendAction } })
export default class SettlementEventName extends BaseEventCell<SettlementEvent> {
  declare params: SettlementParams;
  sportImage = '';
  locationImage = '';

  get hasNote() {
    return this.event?.note;
  }

  get note() {
    if (!this.hasData) return '';
    return this.event?.note || this.$t('event.noNote');
  }

  get settlementNote() {
    return this.params?.data.settlementNote;
  }

  get showSettlementNote() {
    return this.params?.showSettlementNote;
  }

  get showSetTimeNow() {
    return this.params?.showSetTimeNow;
  }

  get sport() {
    if (!this.event) return null;
    return SportStore.getSportById(this.params.data.sportId);
  }

  get eventName() {
    return this.event?.eventName
      ? this.event?.eventName
      : this.event?.home + ' - ' + this.event?.away;
  }

  mounted() {
    this.getImages();
  }

  get sentFromLive() {
    return this.params.data.liveStatus === 'SETTLE';
  }

  get hasNoSettleProvider() {
    return (
      !this.params.data.isManualSettlement &&
      !this.params?.data.liveProvider &&
      !this.params?.data.ctrlProvider
    );
  }

  isMatchLive(event: SettlementEvent) {
    if (!event) return false;
    return !!event.isLiveBooking;
  }

  isSetTimeNowDisabled(event: SettlementEvent) {
    if (!event) return true;
    if (event.playerName) {
      return true;
    }
    return !!event.isLiveBooking;
  }

  async getImages() {
    if (!this.params?.data) return;
    this.sportImage = await getSportImage(this.sport?.code);

    // TODO: add location to settlement event before pushing it to the store
    this.locationImage = await getLocationImage(this.params.data?.locationName);
  }

  toastEventUpdated() {
    this.$notification.success({
      message: this.$t('common.success') as string,
      description: this.$t('event.updated') as string,
    });
  }

  async updateTime() {
    if (!this.event) return;
    // @ts-ignore
    const stage = this.event.stage ?? 'prematch';
    const settle = true;
    const date = new Date().toISOString();
    const [err] = await to(
      EventStore.updateStart({ intKey: this.event.intKey, date, stage, settle })
    );
    if (err) return;

    SettlementStore.editEvent({ eventId: this.event.intKey, key: 'start', newValue: date });

    this.toastEventUpdated();
  }
}
