
import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import { ICellRendererParams } from '@ag-grid-community/core';

import { Editable } from '../Editable';
import { FootballPeriods, FootballPeriod } from '@/modules/settlement/settlement.types';
import { FIFTEEN_MINUTES_IN_MS, MINUTE_IN_MS } from '@/modules/settlement/settlement.constants';
import { SettlementStopwatchStore } from '@/modules/settlementStopwatch/settlementStopwatch.store';
import dayjs from 'dayjs';

@Component({
  name: 'SettlementStopwatch',
  components: {
    Editable,
  },
})
export default class SettlementStopwatch extends Vue {
  params: ICellRendererParams | undefined;
  minuteMs = MINUTE_IN_MS;
  minutes15ms = FIFTEEN_MINUTES_IN_MS;
  internalCounter = 0;
  counterInterval: any;

  get hasData() {
    return !!this.params?.data;
  }

  get eventId() {
    if (!this.hasData) return '';
    return this.params?.data.id;
  }

  get eventPeriod() {
    if (!this.hasData) return '';
    return SettlementStopwatchStore.getEventPeriod(this.eventId);
  }

  get minute() {
    if (!this.hasData) return '';
    const min = SettlementStopwatchStore.getEventTime(this.eventId);
    return min ? min : 0;
  }

  get isLive() {
    if (!this.hasData) return '';
    return this.params?.data.isLiveBooking;
  }

  get time() {
    if (this.internalCounter === 45) return '45+';
    if (this.internalCounter === 90) return '90+';
    return this.minute ? Number(this.minute) : this.internalCounter;
  }

  // only football has minute tracking
  get showMinutesInput() {
    return (
      this.params?.data.sportId === 1 &&
      this.params?.data.isManualSettlement &&
      !this.params?.data.isVerified
    );
  }

  get isMatchInProggress() {
    if (!this.hasData) return false;
    return [
      FootballPeriods.FIRST_HALF,
      FootballPeriods.SECOND_HALF,
      FootballPeriods.OVER_TIME,
    ].includes(this.eventPeriod as FootballPeriods);
  }

  get isChangeDisabled() {
    if (!this.hasData) return true;
    const inFuture = dayjs(this.params?.data.start).diff(dayjs()) > 0;
    return this.isLiveBooking() || inFuture;
  }

  @Watch('internalCounter') //We edit event minute then we use that info for sending result payload
  updateStopwatch(minute: number) {
    if (minute > 120) {
      clearInterval(this.counterInterval);
      this.$notification.warning({
        message: 'Error',
        description: 'Max minutes is 120',
      });
      return;
    }
    SettlementStopwatchStore.setEventTime({
      eventId: this.eventId,
      time: Number(minute),
    });
  }

  @Watch('eventPeriod')
  onPeriodChange(newPeriod: FootballPeriod) {
    const currentMinuteSet = this.minute && Number(this.minute) > 1 ? Number(this.minute) : null;
    clearInterval(this.counterInterval);
    switch (newPeriod) {
      case FootballPeriods.NOT_STARTED:
        this.setCounter(1);
        clearInterval(this.counterInterval);
        break;
      case FootballPeriods.FIRST_HALF:
        this.setCounter(currentMinuteSet || 1);
        this.setMinuteInterval();
        break;
      case FootballPeriods.SECOND_HALF:
        this.setCounter(currentMinuteSet || 46);
        this.setMinuteInterval();
        break;
      case FootballPeriods.HALF_TIME:
        this.setCounter(currentMinuteSet || 45);
        setTimeout(() => {
          this.setCounter(currentMinuteSet || 46);
          this.setMinuteInterval();
        }, this.minutes15ms);
        break;
      case FootballPeriods.FULL_TIME:
        this.setCounter(currentMinuteSet || 90);
        clearInterval(this.counterInterval);
        break;
      case FootballPeriods.OVER_TIME:
        this.setCounter(currentMinuteSet || 91);
        this.setMinuteInterval();
        break;
    }
  }

  isLiveBooking() {
    return this.params?.data.isLiveBooking;
  }

  incrementCounter() {
    this.internalCounter = Number(this.internalCounter) + 1;
  }

  setCounter(newMinutes: number) {
    this.internalCounter = Number(newMinutes);
  }

  setMinuteInterval() {
    this.counterInterval = setInterval(() => this.incrementCounter(), this.minuteMs);
  }

  handleChange(minutes: number) {
    this.setCounter(minutes);
    clearInterval(this.counterInterval);
    if (this.isMatchInProggress) {
      this.setMinuteInterval();
    }
    SettlementStopwatchStore.setEventTempChanges({
      eventId: this.params?.data.id,
      newData: { minute: minutes },
    });
  }

  mounted() {
    if (this.minute) this.setCounter(Number(this.minute));
    if (this.isMatchInProggress) {
      if (this.eventPeriod === FootballPeriods.FIRST_HALF && !this.minute) this.setCounter(1);
      if (this.eventPeriod === FootballPeriods.SECOND_HALF && !this.minute) this.setCounter(46);
      this.setMinuteInterval();
    }
  }

  destroyed() {
    clearInterval(this.counterInterval);
  }
}
