
import Vue from 'vue';
import to from 'await-to-js';
import { Prop, Component, Watch } from 'vue-property-decorator';
import { WrappedFormUtils } from 'ant-design-vue/types/form/form';
import isObject from 'lodash/isObject';

import { SettlementStore } from '@/modules/settlement/settlement.store';
import { SPORTS_PLAYER_RESULTS } from '@/modules/settlement/settlement.constants';
import { SportCode } from '@/modules/sport';
import { CommonStore } from '@/modules/common';
import { EventDetailsSettlement } from '@/ui/components/EventDetailsSettlement';

@Component({ name: 'PlayerMatchDataModal', components: { EventDetailsSettlement } })
export default class PlayerMatchDataModal extends Vue {
  form!: WrappedFormUtils;
  breachedLimits: any = {};
  beforeCreate() {
    this.form = this.$form.createForm(this);
  }

  @Prop({ type: Boolean })
  visible!: boolean;

  @Prop({ type: Function })
  close!: () => void;

  get activeEventId(): string {
    return SettlementStore.activeId as string;
  }

  get resultHistory() {
    return SettlementStore.resultEntries;
  }

  get isLoading() {
    return CommonStore.isFetching;
  }

  get event(): any {
    return SettlementStore.activeEvent;
  }

  get sportCode() {
    if (!this.event) return '';
    return this.event.sportCode;
  }

  isFootball() {
    return SportCode.FOOTBALL === this.sportCode;
  }

  get results() {
    return SPORTS_PLAYER_RESULTS[this.sportCode] || [];
  }

  setResultAndBreachedLimits(settlementHistory: any) {
    const keys = Object.keys(settlementHistory);
    keys.forEach((k: any) => {
      if (!isObject(settlementHistory[k])) {
        this.form.setFieldsValue({ [k]: settlementHistory[k] });
      } else {
        this.form.setFieldsValue({ [k]: settlementHistory[k].value });
      }
      this.breachedLimits[k] = settlementHistory[k].breached_limit;
    });
  }

  @Watch('visible')
  async onVisible(value: any) {
    if (value) {
      const [err, res] = await to<any>(
        SettlementStore.getPlayerResultHistory(this.activeEventId as any)
      );
      if (err) return;
      this.setResultAndBreachedLimits(res.settlement_history);
    } else {
      SettlementStore.clearResultHistory();
      this.breachedLimits = {};
      this.form.resetFields();
    }
  }

  errorMessage(description: string = this.$t('genericError.description') as string) {
    return this.$notification.error({
      message: this.$t('common.error') as string,
      description: description,
    });
  }

  successMessage(description: any) {
    return this.$notification.success({
      message: this.$t('common.success') as string,
      description: description as string,
    });
  }

  async submitResultForm() {
    const [formError] = await to(this.form.validateFields() as any);
    if (formError) return;
    const [err, res] = await to(SettlementStore.setPlayerResult());
    if (err) return;
    this.breachedLimits = res;
    this.successMessage(this.$t('settlement.resultSavedSuccess'));
  }

  async resetMatch() {
    const [err] = await to(SettlementStore.resetAllPlayerOdds(this.activeEventId));
    if (err) return;
    this.successMessage(this.$t('settlement.matchResetSuccess'));
  }

  keyAction({ srcKey }: any) {
    if (srcKey === 'save') {
      this.submitResultForm();
    } else {
      this.close();
    }
  }
}
