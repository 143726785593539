
import Vue from 'vue';
import to from 'await-to-js';
import { LocaleMessages } from 'vue-i18n';
import { Component, Watch } from 'vue-property-decorator';
import { ICellRendererParams } from '@ag-grid-community/core';

import { SettlementStore } from '@/modules/settlement/settlement.store';
import { SPORTS_WITH_VALID_FT_HT_PERIODS } from '@/modules/settlement/settlement.constants';
import { CommonSportPeriods, FootballPeriods } from '@/modules/settlement/settlement.types';
import { SettlementStopwatchStore } from '@/modules/settlementStopwatch/settlementStopwatch.store';
import { FeedStore } from '@/modules/feed';
import {
  CURRENT_RESULT_KEYS_BY_SPORT_SETTLEMENT,
  eventService,
  EVENT_STATE,
  isLive,
} from '@/modules/event';
import { SportStore } from '@/modules/sport';
import { EventStore } from '@/modules/event/event.store';
import { EventTime } from '@/ui/components/EventTime';
import { SPORT_IDS_WITH_SETS } from '@/modules/sport/sport.contants';

@Component({ components: { EventTime } })
export default class SettlementResults extends Vue {
  params: ICellRendererParams | undefined;
  resultChanged = false;
  inputValuesChanged = false;
  disableSettle = true;
  changes: { [key: string]: any } = {};
  lastHomeScore = 0;
  lastAwayScore = 0;
  home = 0;
  away = 0;
  currentPeriodSelected = null;

  get sport() {
    if (!this.event) return null;
    return SportStore.getSportById(this.event.sportId);
  }

  get currentResultKeys() {
    return CURRENT_RESULT_KEYS_BY_SPORT_SETTLEMENT[this.sport?.code];
  }

  get hasData() {
    return !!this.params?.data;
  }

  get tempChanges() {
    return SettlementStopwatchStore.getEventTempChanges(this.eventId);
  }

  get savedResult() {
    return SettlementStopwatchStore.getEventResult(this.eventId);
  }

  get hasValidHtFtPeriods() {
    return SPORTS_WITH_VALID_FT_HT_PERIODS.includes(this.sport?.code);
  }

  get tempHome() {
    if (!this.tempChanges) return null;
    return this.tempChanges.home;
  }

  get tempAway() {
    if (!this.tempChanges) return null;
    return this.tempChanges.away;
  }

  get inputHomeValue(): any {
    return (results: number[], index: number) =>
      this.tempHome ??
      this.savedResult?.home ??
      this.getResult(results, index) ??
      this.lastHomeScore;
  }

  get inputAwayValue(): any {
    return (results: number[], index: number) =>
      this.tempAway ??
      this.savedResult?.away ??
      this.getResult(results, index) ??
      this.lastAwayScore;
  }

  get eventId() {
    if (!this.hasData) return '';
    return this.params?.data.id;
  }

  get eventPeriod() {
    if (!this.hasData) return '';
    return SettlementStopwatchStore.getEventPeriod(this.eventId);
  }

  get eventMinute() {
    if (!this.hasData) return '';
    return SettlementStopwatchStore.getEventTime(this.eventId);
  }

  get isChangeDisabled() {
    if (!this.hasData) return true;
    return !this.params?.data.isManualSettlement || this.params?.data.isVerified;
  }

  // part for implementing live update

  get event(): any {
    if (!this.hasData) return null;
    return (
      EventStore.events[eventService.formatEventId(this.params?.data?.intKey)] ||
      SettlementStore.forSettlement.find((event: any) => event.id === this.eventId)
    );
  }

  get feedCurrentResult() {
    if (!this.event) return null;
    return FeedStore.eventCurrentResult(this.event.intKey);
  }

  get feedPeriodResult() {
    if (!this.event) return null;
    return FeedStore.eventFromFeed(this.event.intKey);
  }

  get currentResult() {
    if (!this.event) return null;
    const data = this.feedCurrentResult ?? this.event.currentResultJson;
    return data || {};
  }

  get lastSettledPeriod() {
    // change HT to FH only on Football as valid period => Backend should send HT as lastsettledperiod only on Football
    if (this.event.settledPeriodsList && this.event.settledPeriodsList.length) {
      return this.event.settledPeriodsList[this.event.settledPeriodsList.length - 1] ===
        CommonSportPeriods.HALF_TIME
        ? FootballPeriods.FIRST_HALF
        : this.event.settledPeriodsList[this.event.settledPeriodsList.length - 1];
    }
    return '';
  }

  get lastPlayingPeriod() {
    if (!this.event) return '';
    return this.event.lastPlayingPeriod;
  }

  get currentPeriod() {
    if (!this.event) return '';
    return this.event.currentPeriod;
  }

  get disableFTAndHT() {
    if (this.hasValidHtFtPeriods) return false;
    const validPeriod =
      this.currentPeriodSelected ?? this.eventPeriod ?? CommonSportPeriods.NOT_STARTED;
    return (
      validPeriod === CommonSportPeriods.FULL_TIME || validPeriod === CommonSportPeriods.HALF_TIME
    );
  }

  mounted() {
    console.log('MOUNTED', this.event, this.event.intKey);
    if (
      this.isStatscoreEvent &&
      this.event.state === EVENT_STATE.COMPLETED &&
      this.lastSettledPeriod !== CommonSportPeriods.FULL_TIME
    ) {
      SettlementStore.updateEventWithoutRender({
        eventId: this.event.intKey,
        key: 'lastPlayingPeriod',
        newValue: CommonSportPeriods.FULL_TIME,
      });
      this.disableSettle = false;
      return;
    }
  }

  enableSettleOnPeriodFinished() {
    console.log('EVENT', this.event, this.event.intKey);
    console.log('LAST_SETTLED_PERIOD', this.lastSettledPeriod, this.event.intKey);
    console.log('CURRENT_PERIOD', this.currentPeriod, this.event.intKey);
    console.log('LAST PLAYING PERIOD', this.lastPlayingPeriod, this.event.intKey);
    if (!this.event) return false;
    if (!this.isStatscoreEvent) return false;
    if (!this.lastPlayingPeriod) return false;
    if (this.lastSettledPeriod === CommonSportPeriods.FULL_TIME) return false;
    if (this.currentPeriod == CommonSportPeriods.NOT_STARTED && this.lastPlayingPeriod) {
      SettlementStore.updateEventWithoutRender({
        eventId: this.event.intKey,
        key: 'currentPeriod',
        newValue: this.lastPlayingPeriod,
      });
    }

    if (
      this.currentPeriod !== this.lastPlayingPeriod &&
      this.lastSettledPeriod !== this.lastPlayingPeriod
    )
      return true;

    return false;
  }

  get isSettleButtonDisabled() {
    return this.params?.data.isSettleDisabled || this.params?.data.isVerified;
  }

  get isStatscoreEvent() {
    if (!this.event) return false;
    return !!this.event.ctrlProvider;
  }

  @Watch('feedPeriodResult')
  handlePeriodSettleButton(val: any) {
    if (val === undefined) return;
    if (!this.event) return;
    if (this.event.isManualSettlement) return;
    const periodsLength = Object.keys(val.r.d_r).length;
    console.log('FEED PERIOD RESULT', val, val.e_id);
    if (periodsLength >= 2) {
      console.log('LAST_PLAYING_PERIOD_1,', Object.keys(val.r.d_r).slice(), val.e_id);
      console.log('LAST_PLAYING_PERIOD,', Object.keys(val.r.d_r).slice(-2)[0], val.e_id);
      SettlementStore.updateEventWithoutRender({
        eventId: this.event.intKey,
        key: 'lastPlayingPeriod',
        newValue: Object.keys(val.r.d_r).slice(-2)[0],
      });
    }
    if (this.enableSettleOnPeriodFinished()) {
      SettlementStore.setSettleBtnState({ eventId: this.eventId, isDisabled: false });
    }
  }

  @Watch('isSettleButtonDisabled', { immediate: true })
  handleSettleButton(val: boolean) {
    if (val === undefined) return;
    this.disableSettle = val;
  }

  @Watch('tempChanges')
  onDataLoaded(changes: any) {
    if (!changes) {
      this.inputValuesChanged = false;
    } else {
      if (changes.period) {
        this.currentPeriodSelected = changes.period;
      }
      this.inputValuesChanged = true;
    }
  }

  isLiveBooking() {
    return this.params?.data.isLiveBooking;
  }

  isInLiveStage() {
    if (
      this.params?.data.liveStatus.toLowerCase().includes('live') ||
      this.params?.data.liveStatus.toLowerCase().includes('break')
    )
      return true;
    return false;
  }

  getResult(results: number[], index: number) {
    if (
      this.event.currentPeriod === CommonSportPeriods.FULL_TIME &&
      SPORT_IDS_WITH_SETS.includes(this.event.sportId)
    ) {
      return this.event.currentResultJson.S[index];
    }
    if (
      (isLive(this.event) || this.isStatscoreEvent) &&
      this.feedPeriodResult &&
      SPORT_IDS_WITH_SETS.includes(this.event.sportId)
    ) {
      if (this.feedPeriodResult.time === CommonSportPeriods.FULL_TIME) {
        return this.feedPeriodResult.r.c_r.S[index];
      }
    }
    // change this when provider switching is available
    // if (!this.isLiveBooking()) return null;
    return results[index] ?? 0;
  }

  // end

  errorMessage(description = this.$t('genericError.description')) {
    return this.$notification.error({
      message: this.$t('common.error') as string,
      description: description as string,
    });
  }

  successMessage(description: string | LocaleMessages) {
    return this.$notification.success({
      message: this.$t('common.success') as string,
      description: description as string,
    });
  }

  async saveChanges() {
    const [err] = await to(
      SettlementStopwatchStore.saveTempChanges({
        eventId: this.eventId,
        isFootball: this.params?.data.sportId === 1,
      })
    );
    if (err) return this.errorMessage();
    this.successMessage(this.$t('settlement.changesSaved'));
    this.disableSettle = false;
    SettlementStore.setSettleBtnState({ eventId: this.eventId, isDisabled: false });
  }

  async settleAll() {
    console.log(
      'IS_STATSCORE EVENT',
      this.isStatscoreEvent,
      this.event.intKey,
      this.lastPlayingPeriod
    );
    const params = {
      period: this.isStatscoreEvent ? this.lastPlayingPeriod : this.eventPeriod,
      minute: this.isStatscoreEvent ? null : this.eventMinute,
      resolving_by: 'period',
    };

    // @ts-ignore-next-line
    if (!params.minute) delete params.minute;

    const [err] = await to(
      SettlementStore.settleAll({
        eventId: this.eventId,
        params: params,
      })
    );
    if (err) return;
    this.resultChanged = false;
    this.disableSettle = true;
    SettlementStore.updateEventWithoutRender({
      eventId: this.eventId,
      key: 'settledPeriodsList',
      newValue: [this.lastPlayingPeriod],
    });
    SettlementStore.setSettleBtnState({ eventId: this.eventId, isDisabled: true });
    return this.successMessage(this.$t('settlement.outcomesSettledSuccessfully'));
  }

  onHomeChange(val: any) {
    SettlementStopwatchStore.setEventTempChanges({
      eventId: this.params?.data.id,
      newData: { home: val },
    });
  }

  onAwayChange(val: any) {
    SettlementStopwatchStore.setEventTempChanges({
      eventId: this.params?.data.id,
      newData: { away: val },
    });
  }

  onKeyUp(keyEvent: KeyboardEvent) {
    if (!this.inputValuesChanged) return;
    if (keyEvent.code === 'Space' || keyEvent.keyCode === 13) {
      this.saveChanges();
    }
  }

  onRightClick() {
    if (!this.inputValuesChanged) return;
    SettlementStopwatchStore.clearEventTempChanges(this.eventId);
  }
}
