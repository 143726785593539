import { SportCode } from '../sport';

interface PeriodConfig {
  [key: string]: any;
}

// KOSARKA:
// FT, REG, Q1, Q1_E, Q2_E, Q3_E, Q4_E, M1
// Aleksin predlog:
// Q1, Q1_E ... Q4, Q4_E / ( sta je reg i M1 ?? to mi na frontu ne treba posto ne postoji kao period u basketu)

// HOKEJ:
// FT, P1, (koristi se i current period, ali valjda to isto samo u lajvu?)
// Aleksin predlog:
// FT, P1, P1_E, ... P3, P3_E

// RUKOMET:
// FT, HT, FH (first half), SH (second half) <- ako padne gol tokom perioda
// Aleksin predlog:
// Msm da je ovo gore dobro

// BASKET3x3:
// FT,
// Aleksin predlog:
// Vrv nam ne treba nista  vise ovde, kontam da je 3x3 ponuda oskudna? I msm da nema periode nikakve

// AMERICKI FUDBAL:
// FT, FH, HT, Q1,
// Aleksin predlog:
// FT, Q1, Q1_E ... Q4, Q4_E

// BEJZBOL:
// FT, FH,
// Aleksin predlog
// Bejzbol ima nekih 9 inninga ( valjda inning traje dok je god jedan isti thrower na terenu ), a ne uspevam da nadjem nikakvu pricu o First Half-u , pa bih rekao da je za bejzbol samo FT dovoljan, mozes jovanu da pingas za ovo?

// BORILACKE VESTINE (lmao):
// FT
// Aleksin predlog :
// Lmao indeed - Odakle bre nama borilacke vestine ???

// FUTSAL:
// FT, HT, FH, SH,
// Aleksin predlog :
// Ovo je dobro rekao bih

// ODBOJKA:
// FT, SET1 (koristi se i current set, ali isto valjda samo lajv?)
// Aleksin predlog:
// FT, SET1 (S1 meejbi da se zove heh 👀) SET1_E ... SET5, SET5_E

// PIKADO:
// FT
// Aleksin predlog
// Valjda je toto

// SNUKER:
// FT
// Aleksin predlog
// Valjda je toto

// STONI TENIS:
// FT, SET1
// Aleksin predlog
// Ne znam kolko setova moze imati? pa da l nam treba opet SET1, SET1_E ... SET3, SET3_E

// VATERPOLO:
// FT
// FT Q1,Q1_E .... Q4
const AMERICAN_FOOTBALL_PERIODS_MAP = {
  // check this for M1 , maybe it exist
  NS: {
    next: 'Q1',
  },
  Q1: {
    next: 'Q2',
  },
  Q2: {
    next: 'Q3',
  },
  Q3: {
    next: 'Q4',
  },
  Q4: {
    next: 'OT',
  },
  OT: {
    next: 'FT',
  },
  FT: {
    next: 'NS',
  },
};

const FOOTBALL_PERIODS_MAP = {
  NS: {
    next: 'FH',
    nextMinute: 1,
  },
  FH: {
    next: 'HT',
    nextMinute: 45,
  },
  HT: {
    next: 'SH',
    nextMinute: 46,
  },
  SH: {
    next: 'FT',
    nextMinute: 90,
  },
  FT: {
    next: 'OT',
    nextMinute: 91,
  },
  OT: {
    next: 'NS',
    nextMinute: 0,
  },
};

const FUTSAL_PERIODS_MAP = {
  NS: {
    next: 'FH',
  },
  FH: {
    next: 'HT',
  },
  HT: {
    next: 'SH',
  },
  SH: {
    next: 'OT',
  },
  OT: {
    next: 'FT',
  },
  FT: {
    next: 'NS',
  },
};

const HANDBALL_PERIODS_MAP = FUTSAL_PERIODS_MAP;

const BASKETBALL_PERIODS_MAP = {
  NS: {
    next: 'M1',
  },
  M1: {
    next: 'Q1',
  },
  Q1: {
    next: 'Q2',
  },
  Q2: {
    next: 'HT',
  },
  HT: {
    next: 'Q3',
  },
  Q3: {
    next: 'Q4',
  },
  Q4: {
    next: 'OT',
  },
  OT: {
    next: 'FT',
  },
  FT: {
    next: 'NS',
  },
};

const WATERPOLO_PERIODS_MAP = AMERICAN_FOOTBALL_PERIODS_MAP;

const HOCKEY_PERIODS_MAP = {
  NS: {
    next: 'P1',
  },
  P1: {
    next: 'P2',
  },
  P2: {
    next: 'P3',
  },
  P3: {
    next: 'OT',
  },
  OT: {
    next: 'FT',
  },
  FT: {
    next: 'NS',
  },
};

const BASKETBALL3x3_PERIODS_MAP = {
  NS: {
    next: 'FT',
  },
  FT: {
    next: 'NS',
  },
};

const BASEBALL_PERIODS_MAP = {
  NS: {
    next: '1/9',
  },
  '1/9': {
    next: '2/9',
  },
  '2/9': {
    next: '3/9',
  },
  '3/9': {
    next: '4/9',
  },
  '4/9': {
    next: '5/9',
  },
  '5/9': {
    next: '6/9',
  },
  '6/9': {
    next: '7/9',
  },
  '7/9': {
    next: '8/9',
  },
  '8/9': {
    next: '9/9',
  },
  '9/9': {
    next: 'OT',
  },
  OT: {
    next: 'FT',
  },
  FT: {
    next: 'NS',
  },
};

const DARTS_PERIODS_MAP = {
  NS: {
    next: 'FT',
  },
  FT: {
    next: 'NS',
  },
};

const SNOOKER_PERIODS_MAP = {
  NS: {
    next: 'FT',
  },
  FT: {
    next: 'NS',
  },
};

const TENNIS_PERIODS_MAP = {
  NS: {
    next: 'SET1',
  },
  SET1: {
    next: 'SET2',
  },
  SET2: {
    next: 'SET3',
  },
  SET3: {
    next: 'SET4',
  },
  SET4: {
    next: 'SET5',
  },
  SET5: {
    next: 'FT',
  },
  FT: {
    next: 'NS',
  },
};

const TABLE_TENNIS_PERIODS_MAP = {
  NS: {
    next: 'SET1',
  },
  SET1: {
    next: 'SET2',
  },
  SET2: {
    next: 'SET3',
  },
  SET3: {
    next: 'SET4',
  },
  SET4: {
    next: 'SET5',
  },
  SET5: {
    next: 'FT',
  },
  FT: {
    next: 'NS',
  },
};

const VOLEYBALL_PERIODS_MAP = {
  NS: {
    next: 'SET1',
  },
  SET1: {
    next: 'SET2',
  },
  SET2: {
    next: 'SET3',
  },
  SET3: {
    next: 'SET4',
  },
  SET4: {
    next: 'SET5',
  },
  SET5: {
    next: 'FT',
  },
  FT: {
    next: 'NS',
  },
};

const MMA_PERIODS_MAP = {
  NS: {
    next: 'FT',
  },
  FT: {
    next: 'NS',
  },
};

export const SETTLEMENT_PERIODS_BY_SPORT: PeriodConfig = {
  [SportCode.FOOTBALL]: FOOTBALL_PERIODS_MAP,
  [SportCode.BASKETBALL]: BASKETBALL_PERIODS_MAP,
  [SportCode.AMERICAN_FOOTBALL]: AMERICAN_FOOTBALL_PERIODS_MAP,
  [SportCode.HOCKEY]: HOCKEY_PERIODS_MAP,
  [SportCode.HANDBALL]: HANDBALL_PERIODS_MAP,
  [SportCode.BASKETBALL_3X3]: BASKETBALL3x3_PERIODS_MAP,
  [SportCode.FUTSAL]: FUTSAL_PERIODS_MAP,
  [SportCode.BASEBALL]: BASEBALL_PERIODS_MAP,
  [SportCode.VOLEYBALL]: VOLEYBALL_PERIODS_MAP,
  [SportCode.TABLE_TENNIS]: TABLE_TENNIS_PERIODS_MAP,
  [SportCode.SNOOKER]: SNOOKER_PERIODS_MAP,
  [SportCode.DARTS]: DARTS_PERIODS_MAP,
  [SportCode.WATERPOLO]: WATERPOLO_PERIODS_MAP,
  [SportCode.TENNIS]: TENNIS_PERIODS_MAP,
  [SportCode.MMA]: MMA_PERIODS_MAP,
};
