
// @ts-nocheck
import { EventStore } from '@/modules/event/event.store';
import { SettlementStore } from '@/modules/settlement/settlement.store';
import to from 'await-to-js';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component({ name: 'SettlemenNotesModal' })
export default class SettlementNotesModal extends Vue {
  @Prop({ required: true, type: Boolean })
  visible!: boolean;

  @Prop({ required: true })
  close!: () => void;

  @Prop({ required: true })
  eventId!: string | null;

  @Prop({ default: false })
  isReadOnly!: boolean;

  @Prop({ default: 'DUAL' })
  eventType!: 'PLAYER' | 'DUAL';

  nextEventNote: string | null = null;
  nextSettlementNote: string | null = null;

  get event() {
    return SettlementStore.activeEvent;
  }

  get settlementNote() {
    return this.nextSettlementNote ?? this.event.settlementNote;
  }

  get eventNote() {
    return this.nextEventNote ?? this.event.note;
  }

  onEventNoteChange({ target }: any) {
    this.nextEventNote = target.value;
  }

  onSettlementNoteChange({ target }: any) {
    this.nextSettlementNote = target.value;
  }

  successMessage(description: string) {
    return this.$notification.success({
      message: this.$t('common.success') as string,
      description: description,
    });
  }

  async saveEventNote() {
    const [err] = await to(
      EventStore.updateNote({
        intKey: this.event.intKey,
        note: this.eventNote,
        eventType: 'live',
      })
    );
    if (err) return;
    this.successMessage(this.$t('event.updated') as string);
  }

  async saveSettlementNote() {
    const req =
      this.eventType === 'DUAL'
        ? SettlementStore.updateSettlementNote
        : SettlementStore.updatePlayerSettlementNote;
    const [err] = await to(
      req({
        eventId: this.eventId,
        settlementNote: this.settlementNote,
      })
    );
    if (err) return;
    this.$emit('noteUpdated', this.eventId);
    this.successMessage(this.$t('event.updated') as string);
  }

  handleClose() {
    this.nextEventNote = null;
    this.nextSettlementNote = null;
    this.close();
    SettlementStore.clearActiveEvent();
  }
}
