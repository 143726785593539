
import Component from 'vue-class-component';
import isEmpty from 'lodash/isEmpty';
import dayjs from 'dayjs';

import {
  CURRENT_RESULT_KEYS_BY_SPORT,
  eventService,
  LiveEvent,
  PERIOD_RESULT_KEYS_BY_SPORT,
} from '@/modules/event';
import { SportStore, SPORT_WHEN_TO_HIDE_PERIOD_RESULT } from '@/modules/sport';
import BaseEventCell from './BaseEventCell';
import { FeedStore } from '@/modules/feed';
import { SHORT_DATE_TIME } from '@/constants';

@Component
export default class EventLiveResults extends BaseEventCell<LiveEvent> {
  get sport() {
    if (!this.event) return null;
    return SportStore.getSportById(this.event.sportId);
  }

  get currentResultKeys() {
    return CURRENT_RESULT_KEYS_BY_SPORT[this.sport?.code];
  }

  get periodResultKeys() {
    return PERIOD_RESULT_KEYS_BY_SPORT[this.sport?.code];
  }

  get feedCurrentResult() {
    if (!this.event) return null;
    return FeedStore.eventCurrentResult(this.event.intKey);
  }

  get feedPeriodResult() {
    if (!this.event) return null;
    return FeedStore.eventPeriodResult(this.event.intKey);
  }

  get currentResult() {
    if (!this.event) return null;
    const data = this.feedCurrentResult ?? this.event.currentResultJson;
    return data || {};
  }

  get hasCurrentResult() {
    return !isEmpty(this.currentResult);
  }

  get periodFromRest() {
    if (!this.event) return '';
    return this.event.periodResultJson;
  }

  get allPeriodResults() {
    return this.feedPeriodResult ? this.feedPeriodResult : this.periodFromRest;
  }

  get time() {
    if (!this.event) return '';

    const eventTime = dayjs(this.event.start).format(SHORT_DATE_TIME);
    const eventResult = FeedStore.results[this.getAccesor(this.event.intKey)];
    return eventService.eventTime({
      currentPhase: this.event.currentPhaseJson,
      startTime: eventTime,
      result: eventResult,
      liveStatus: this.event?.liveStatus,
    });
  }

  get periodName() {
    if (!this.event) return '';

    const periodFromTime = this.time.substring(0, 2);

    if (Number.isNaN(parseInt(periodFromTime))) {
      return periodFromTime;
    }

    if (this.feedPeriodResult) {
      return this.getPeriodName(this.feedPeriodResult);
    } else {
      return this.getPeriodName(this.periodFromRest);
    }
  }

  get showPeriodResult() {
    if (!this.event) return false;
    if (!this.periodName) return true;

    const hidePeriods = SPORT_WHEN_TO_HIDE_PERIOD_RESULT[this.sport?.code];
    return !hidePeriods.includes(this.periodName);
  }

  get periodResult() {
    if (!this.allPeriodResults || !this.periodResultKeys) return [];
    return (
      eventService.getLiveImportantPeriodResults(this.allPeriodResults, this.periodResultKeys) || []
    );
  }

  getAccesor = (intKey: number | string) => eventService.formatEventId(intKey);

  getPeriodName(periodResults: any) {
    const keys = Object.keys(periodResults || {});
    return keys[keys.length - 1] || '';
  }

  openMatchData() {
    this.params.openMatchDataModal(
      this.event?.intKey,
      SportStore.getSportById(this.event?.sportId)?.code
    );
  }

  getResult(results: any, index: number) {
    return results[index] ?? 0;
  }
}
