import { i18n } from '@/ui/locales/setupLocale';
import { SportCode } from '../sport';

export const ASSIGNEE_SELECT_OPTIONS = [
  { value: 'assigned', label: i18n.t('selectOptions.assigned') },
  { value: 'unAssigned', label: i18n.t('selectOptions.unAssigned') },
  { value: 'assignedToMe', label: i18n.t('selectOptions.assignedToMe') },
  { value: '', label: i18n.t('selectOptions.all') },
];

export const importantPeriods: { [key: string]: any } = {
  fud: ['15', '30', '45', '90'],
};

export const MINUTE_IN_MS = 60000;
export const FIFTEEN_MINUTES_IN_MS = 900000;

export const SPORTS_PLAYER_RESULTS: { [key: string]: any } = {
  [SportCode.FOOTBALL]: [
    { label: i18n.t('settlement.goals'), value: 'G', key: 'G' },
    { label: i18n.t('settlement.fantasyPoints'), value: 'P', key: 'P' },
    // { label: i18n.t('settlement.cards'), key: 'C' },
  ],
  [SportCode.HANDBALL]: [{ label: i18n.t('settlement.goals'), value: 'G', key: 'G' }],
  [SportCode.BASKETBALL]: [
    { label: i18n.t('settlement.points'), value: 'P', key: 'P' },
    { label: i18n.t('settlement.assists'), value: 'A', key: 'A' },
    { label: i18n.t('settlement.jumps'), value: 'S', key: 'S' },
  ],
};

export const SPORTS_WITH_VALID_FT_HT_PERIODS: string[] = [
  SportCode.DARTS,
  SportCode.SNOOKER,
  SportCode.MMA,
  SportCode.BASEBALL,
];
