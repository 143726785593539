
import Vue from 'vue';
import to from 'await-to-js';
import Component from 'vue-class-component';
import { ICellRendererParams } from '@ag-grid-community/core';
import { SettlementStore } from '@/modules/settlement/settlement.store';
import { AuthStore } from '@/modules/auth';

interface AssigneUser extends ICellRendererParams {
  users: { label: string; value: number }[];
}

@Component
export default class AssigneUserDropdown extends Vue {
  params: AssigneUser | undefined;

  get users() {
    return this.params?.users;
  }

  get assigneeId() {
    return this.params?.data.assignee;
  }

  get playerEventId() {
    return this.params?.data.id;
  }

  get dualEventId() {
    return this.params?.data.eventIntKey;
  }

  get buttonText() {
    const eventAssigned = this.users?.find(user => user.value === this.assigneeId);
    if (eventAssigned?.value === AuthStore.userId) return this.$t('settlement.assignedToMe');
    if (eventAssigned) return eventAssigned.label;
    return this.$t('settlement.assign');
  }

  successMessage(message: string) {
    this.$notification.success({
      message: this.$t('common.success') as string,
      description: message,
    });
  }

  async pickDualEventPlayerEvents(user: { value: number; label: string }) {
    const [err] = await to(
      SettlementStore.assignAllDualEventPlayerEventsToUser({
        eventsIds: [this.dualEventId],
        assigneeId: user.value,
      })
    );
    if (err) {
      return Promise.reject();
    }
    return this.successMessage(this.$t('settlement.descendantsPickSuccess') as string);
  }

  async pickPlayerEvent(user: { value: number; label: string }) {
    const [err] = await to(
      SettlementStore.assignPlayerEventToUser({
        events: [this.playerEventId],
        assigneeId: user.value,
      })
    );
    if (err) {
      return Promise.reject();
    }
    return this.successMessage(this.$t('settlement.pickSuccess') as string);
  }

  handleAllDualEventDescendantsAction(event: any, user: { value: number; label: string }) {
    const req =
      event.item.parentMenu.eventKey === 'event'
        ? this.pickDualEventPlayerEvents
        : this.pickPlayerEvent;
    req(user);
  }

  isUserAssigned(userId: number) {
    return AuthStore.userId === userId;
  }
}
