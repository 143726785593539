
import Vue from 'vue';
import Component from 'vue-class-component';
import { ICellRendererParams } from '@ag-grid-community/core';
import { SettlementStore } from '@/modules/settlement/settlement.store';
import to from 'await-to-js';
import { SettlementStopwatchStore } from '@/modules/settlementStopwatch/settlementStopwatch.store';
import { SettlementEvent } from '@/modules/settlement/settlement.types';
import { FeedStore } from '@/modules/feed';
import { eventService } from '@/modules/event';

interface CheckboxCellProps extends ICellRendererParams {
  onChange: (arg?: any) => {};
  checkedKey: string;
}

@Component
export default class CheckboxCell extends Vue {
  params: CheckboxCellProps | undefined;

  get hasData() {
    return !!this.params?.data;
  }

  get eventId() {
    if (!this.hasData) return '';
    return this.params?.data.id;
  }

  get eventMinute(): number {
    if (!this.hasData) return 0;
    return Number(SettlementStopwatchStore.getEventTime(this.eventId));
  }

  // This is for when we get a flag on BE response
  get isChecked() {
    if (!this.hasData) return false;
    return this.params?.data[this.params.checkedKey];
  }

  get has1530Bets() {
    if (!this.hasData) return false;
    return this.params?.data.checkbox1530;
  }

  get liveFootbalEventTime() {
    if (this.params?.data.sportId !== 1) return 1;
    let eventTimeOnInitialLoad: number | string = 0;
    const currentPhase = this.params?.data.currentPhaseJson;

    const liveEventTime = Number(
      eventService.liveEventTimeFootballSettle(
        FeedStore.results[eventService.formatEventId(this.params?.data.intKey)]?.time
      )
    );
    if ('T' in currentPhase) {
      eventTimeOnInitialLoad = eventService.eventTimeOnInitalLoad(currentPhase.T);
    } else {
      eventTimeOnInitialLoad = eventService.liveEventTimeFootballSettle(currentPhase.SN);
    }
    return liveEventTime || eventTimeOnInitialLoad;
  }

  get isDisabled() {
    if (this.params?.data.isVerified) return true;
    if (
      !this.params?.data.isManualSettlement &&
      !this.params?.data.liveProvider &&
      !this.params?.data.ctrlProvider
    )
      return true;
    if (
      (!!this.params?.data.ctrlProvider && this.params.data.currentPeriod != 'NSY') ||
      (!!this.params?.data.liveStatus && this.params.data.liveStatus != 'NOT_STARTED')
    ) {
      console.log('LIVE_CHECKED_KEY', this.params?.checkedKey, this.params?.data.intKey);
      console.log('LIVE_FOOTBALL_TIME', this.liveFootbalEventTime, this.params?.data.intKey);
      switch (this.params?.checkedKey) {
        case 'settled15':
          if (this.liveFootbalEventTime <= 15) return true;
          break;
        case 'settled30':
          if (this.liveFootbalEventTime <= 30) return true;
          break;
      }
      return false;
    } else {
      console.log('PREMATCH_CHECKED_KEY', this.params?.checkedKey, this.params?.data.intKey);
      console.log('PREMATCH_EVENT_MINUTE', this.eventMinute, this.params?.data.intKey);
      switch (this.params?.checkedKey) {
        case 'settled15':
          if (!this.eventMinute) return true;
          if (this.eventMinute <= 15) return true;
          break;
        case 'settled30':
          if (!this.eventMinute) return true;
          if (this.eventMinute <= 30) return true;
          break;
      }
      return false;
    }
  }

  handleChange(checkboxState: boolean) {
    if (checkboxState) {
      this.settleAll();
    }
  }

  errorMessage(description: string = this.$t('genericError.description') as string) {
    return this.$notification.error({
      message: this.$t('common.error') as string,
      description: description,
    });
  }

  successMessage(description: string) {
    return this.$notification.success({
      message: this.$t('common.success') as string,
      description: description,
    });
  }

  async settleAll() {
    if (!this.params) return;
    const [err] = await to(
      SettlementStore.settleAll({
        eventId: this.eventId,
        params: {
          manual: true,
          period: this.params?.checkedKey === 'settled15' ? 'p15' : 'p30',
          minute: this.params?.checkedKey === 'settled15' ? 15 : 30,
          resolving_by: 'minute',
        },
      })
    );
    if (err) return;
    SettlementStore.editEvent({
      eventId: this.eventId,
      key: this.params.checkedKey as keyof SettlementEvent,
      newValue: true,
    });
    this.params.onChange(this.eventId);
    return this.successMessage(this.$t('settlement.outcomesSettledSuccessfully') as string);
  }
}
