import Vue from 'vue';
import get from 'lodash/get';
import Component from 'vue-class-component';

import { ICellRendererParams } from '@ag-grid-community/core';
import { SettlementStore } from '@/modules/settlement/settlement.store';

// Make generic by extending the required cell with settle event type
@Component
export default class BaseSettlementCell extends Vue {
  params: ICellRendererParams | undefined;

  get hasData() {
    return !!this.params?.data;
  }

  get rowData() {
    return this.params?.data;
  }

  get event() {
    if (!this.hasData) return;
    const id = this.params?.data?.id;
    return SettlementStore.forSettlement.find((event: any) => event.id === id) as any;
  }

  get rowHeight() {
    const height = get(this.params, 'api.rowModel.rowHeight', 16);
    return `${height}px`;
  }

  get cellWidth(): string {
    const width = get(this.params, 'colDef.width', 100);
    return `${width}px`;
  }
}
