
import Vue from 'vue';
import Component from 'vue-class-component';
import { ICellRendererParams } from '@ag-grid-community/core';
import { SettlementStore } from '@/modules/settlement/settlement.store';
import { OddStatus } from '@/modules/settlement/settlement.types';
import { SettlementAntepostStore } from '@/modules/settlementAntepost/settlementAntepost.store';
import isEmpty from 'lodash/isEmpty';
interface ResolveOddButtonParams extends ICellRendererParams {
  buttonText: string;
  eventType: string;
  isOddOne: boolean;
}

@Component
export default class ResolveOddButton extends Vue {
  params: ResolveOddButtonParams | undefined;

  get hasData() {
    return !!this.params?.data;
  }

  get eventType() {
    return this.params?.eventType;
  }

  get store() {
    if (this.isAntepostEvent) return SettlementAntepostStore;
    return SettlementStore;
  }

  get isAntepostEvent() {
    if (this.eventType === 'ANTEPOST') return true;
    return false;
  }

  get buttonText() {
    if (!this.hasData) return '';
    return this.params?.buttonText;
  }

  get buttonTypeModifier() {
    if (!this.hasData) return 'default';
    if (this.buttonText === 'Lost' && this.lostOdds.includes(this.oddId)) return 'danger';
    if (this.buttonText === 'Won' && this.wonOdds.includes(this.oddId)) return 'primary';
    if (this.buttonText === '1' && this.oddOneOdds.includes(this.oddId)) return 'primary';
    if (this.buttonText === '1' && this.hasTimeOutcomeOneNote) return 'warning';

    return 'default';
  }

  get eventId() {
    if (!this.params?.data) return '';
    return this.params?.data.id;
  }

  get oddId() {
    if (!this.params?.data) return '';
    return this.params?.data.odd_id || this.params?.data.intKey;
  }

  get lostOdds() {
    return this.store.settleOddsPayload.odds.LOST;
  }

  get wonOdds() {
    return this.store.settleOddsPayload.odds.WON;
  }

  get oddOneOdds() {
    return this.store.settleOddsPayload.odds.ODD_ONE;
  }

  get hasTimeOutcomeOneNote() {
    if (this.isAntepostEvent) {
      if (
        this.params?.isOddOne &&
        !this.isEmpty(this.params?.data.oddNoteList) &&
        this.params?.data.manualStatus === OddStatus.UNRESOLVED
      )
        return true;
      return false;
    } else {
      if (
        this.params?.isOddOne &&
        this.params?.data.odd_note &&
        this.params?.data.odd_status === OddStatus.UNRESOLVED
      )
        return true;
      return false;
    }
  }

  get showNote() {
    if (this.isAntepostEvent) {
      // oddNoteList object key is comming from stream so we need to check wich key to use
      const keyToUse = this.oddNoteListKey(this.params?.data);
      if (this.params?.isOddOne && !this.isEmpty(this.params?.data[keyToUse])) {
        return this.params?.data[keyToUse];
      }
      return [];
    } else {
      if (this.params?.isOddOne && this.params?.data.odd_note) {
        return this.params?.data.odd_note;
      }
      return [];
    }
  }

  get changeButtonColor() {
    if (this.isAntepostEvent) {
      const keyToUse = this.oddNoteListKey(this.params?.data);
      if (
        this.params?.isOddOne &&
        !this.isEmpty(this.params?.data[keyToUse]) &&
        this.params?.data.manualStatus !== OddStatus.CANCELLED &&
        !this.oddOneOdds.includes(this.oddId)
      )
        return 'warning';
      return '';
    } else {
      if (
        this.params?.isOddOne &&
        this.params?.data.odd_note &&
        this.params?.data.odd_status !== OddStatus.CANCELLED &&
        !this.oddOneOdds.includes(this.oddId)
      )
        return 'warning';
      return '';
    }
  }

  isEmpty(value: any) {
    return isEmpty(value);
  }

  async onSettleOutcomePassed() {
    this.store.setOddForSettlement({ oddId: this.oddId, type: 'WON' });
  }

  async onSettleOutcomeFailed() {
    this.store.setOddForSettlement({ oddId: this.oddId, type: 'LOST' });
  }

  async onSettleOutcomeOddOne() {
    this.store.setOddForSettlement({ oddId: this.oddId, type: 'ODD_ONE' });
  }

  oddNoteListKey(object: any) {
    if (!object) return '';
    if ('oddNoteList' in object) return 'oddNoteList';
    return 'oddNote';
  }

  onClickHandler() {
    let clickFunction;
    switch (this.buttonText) {
      case 'Won':
        clickFunction = this.onSettleOutcomePassed;
        break;
      case 'Lost':
        clickFunction = this.onSettleOutcomeFailed;
        break;
      case '1':
        clickFunction = this.onSettleOutcomeOddOne;
        break;
    }
    if (clickFunction) return clickFunction();
  }
}
