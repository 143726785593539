
import to from 'await-to-js';
import Component from 'vue-class-component';
import { ICellRendererParams } from '@ag-grid-community/core';

import { WithNetworkStatus } from '@/ui/mixins/WithNetworkStatus';
import { SPORTS_PLAYER_RESULTS } from '@/modules/settlement/settlement.constants';
import { Mixins } from 'vue-property-decorator';
import isEmpty from 'lodash/isEmpty';
import { SettlementStore } from '@/modules/settlement/settlement.store';
import { eventService } from '@/modules/event';
import { SportCode } from '@/modules/sport';

type PlayerSettleByMarketParmas = ICellRendererParams;

@Component
export default class PlayerSettleByMarket extends Mixins(WithNetworkStatus) {
  params: PlayerSettleByMarketParmas | undefined;
  playerMarkets = SPORTS_PLAYER_RESULTS;
  selectedOutcomeKey = '';
  tempValue = '';
  showPopConfirm = false;

  get eventId() {
    if (!this.params?.data) return '';
    return this.params?.data.id || this.params?.data.intKey;
  }

  get defaultSelected() {
    return (
      Object.keys(SettlementStore.tempPlayerValues)[0] || this.playerMarkets[this.sportCode][0].key
    );
  }

  get fullMarketName() {
    return (shortMarket: string, sportCode: string) => {
      const fullMarketName = SPORTS_PLAYER_RESULTS[sportCode].find(
        (market: any) => market.key === shortMarket
      );
      if (fullMarketName) return fullMarketName.label;
      return '';
    };
  }

  get sportCode() {
    return this.params?.data.sportCode;
  }

  get playerSettlementHistory() {
    const eventAccessor = eventService.formatEventId(this.eventId);
    return SettlementStore.settlementEvents[eventAccessor].settlementHistory;
  }

  get playerOutcomeValue() {
    if (parseInt(this.tempValue) === 0) return 0;
    return parseInt(this.tempValue) || this.playerSettlementHistory[this.selectedOutcomeKey]?.value;
  }

  get playerMarketsBySport() {
    return this.playerMarkets[this.sportCode];
  }

  get isDisabled() {
    if (!this.tempValue || Number(this.tempValue) < 0) return true;
    return false;
  }

  get breachedLimit() {
    if (
      isEmpty(this.params?.data.settlementHistory) ||
      !this.params?.data.settlementHistory[this.selectedOutcomeKey]
    )
      return '';
    if ('breached_limit' in this.params?.data.settlementHistory[this.selectedOutcomeKey]) {
      return this.params?.data.settlementHistory[this.selectedOutcomeKey].breached_limit;
    }
    return '';
  }

  get isFirstToScore() {
    if (this.isFootball) {
      return this.params?.data.settlementHistory.fps;
    }
    return false;
  }

  get enableSettle() {
    return this.params?.data.isSettleEnabled;
  }

  onMarketChange(event: any) {
    this.selectedOutcomeKey = event.key;
    this.tempValue = '';
  }

  successMessage(description: string) {
    return this.$notification.success({
      message: this.$t('common.success') as string,
      description: description,
    });
  }

  get isFootball() {
    return SportCode.FOOTBALL === this.sportCode;
  }

  onChange(value: string) {
    this.tempValue = value.toString();
    const playerResults = {
      [this.selectedOutcomeKey]: {
        value: this.tempValue,
      },
    };
    SettlementStore.setPlayerTempValues({
      eventIntKey: this.eventId,
      playerValues: playerResults,
    });
  }

  setFirstToScore() {
    this.showPopConfirm = true;
  }

  async saveFirstToScore() {
    const [err] = await to(
      SettlementStore.setFirstPlayerToScore({
        intKey: this.eventId,
        payload: {
          fps: !this.isFirstToScore,
        },
        eventIntKey: this.params?.data.eventIntKey,
      })
    );
    if (err) return;
    this.successMessage(this.$t('settlement.firstToScoreSuccess') as string);
  }

  mounted() {
    if (isEmpty(SettlementStore.tempPlayerValues)) {
      this.selectedOutcomeKey = this.playerMarkets[this.sportCode][0].key;
      return;
    }
    this.selectedOutcomeKey = Object.keys(SettlementStore.tempPlayerValues)[0] as string;
  }

  async onSavePlayerResults() {
    const [err] = await to(SettlementStore.setPlayerResult());
    if (err) return;
    this.successMessage(this.$t('settlement.settleSuccess') as string);
    this.tempValue = '';
  }

  async onSettleAllPlayer() {
    const [err] = await to(SettlementStore.settleAllPlayer(this.eventId));
    if (err) return;
    this.successMessage(this.$t('settlement.settleSuccess') as string);
  }
}
