
import { Mixins, Component, Watch } from 'vue-property-decorator';
import to from 'await-to-js';

import WithSettlement from '@/ui/mixins/WithSettlement';
import { SettlementStore } from '@/modules/settlement/settlement.store';
import PickUnpickPlayerEvent from '@/ui/components/CellRenderers/PickUnpickPlayerEvent.vue';
import PlayerSettleByMarket from '@/ui/pages/player-settlement/components/PlayerSettleByMarket.vue';
import PlayerMatchDataModal from '@/ui/pages/player-settlement/components/PlayerMatchDataModal.vue';
import AssignPlayerUserDropdown from '@/ui/pages/player-settlement/components/AssignePlayerUserDropdown.vue';
import { WithGrpcEvents } from '@/ui/mixins/WithGrpcEvents';
import { SportStore } from '@/modules/sport/sport.store';
import { AuthStore } from '@/modules/auth/auth.store';
import SettlePlayerTableHeader from './components/SettlePlayerTableHeader.vue';

@Component({
  components: {
    PickUnpickPlayerEvent,
    PlayerMatchDataModal,
    PlayerSettleByMarket,
    AssignPlayerUserDropdown,
    SettlePlayerTableHeader,
  },
})
export default class PlayerSettlementPage extends Mixins(WithSettlement, WithGrpcEvents) {
  selectedEventId = 0;

  get BASE_COLUMNS(): any {
    return [
      {
        headerName: this.$t('event.name'),
        width: 385,
        cellClass: 'pl-6',
        lockPosition: true,
        cellRendererFramework: 'SettlementEventName',
        cellRendererParams: {
          showSettlementNote: true,
          showSetTimeNow: true,
        },
      },
      {
        headerName: this.$t('event.code'),
        field: 'landbaseCode',
        width: 125,
      },
      {
        headerName: this.$t('settlement.playerName'),
        cellStyle: { display: 'flex', 'align-items': 'center', 'justify-content': 'center' },
        width: 280,
        field: 'playerName',
      },
      {
        headerName: this.$t('settlement.settlementNote'),
        flex: 1,
        field: 'settlementNote',
        cellStyle: { display: 'flex', 'align-items': 'center', 'justify-content': 'start' },
      },
      this.isAdmin && {
        headerName: this.$t('settlement.pick'),
        cellRendererFramework: 'AssignPlayerUserDropdown',
        width: 185,
        pinned: 'right',
        cellStyle: { display: 'flex', 'align-items': 'center', 'justify-content': 'center' },
        cellRendererParams: {
          users: this.usersForAssigneDropdown,
        },
      },
      !this.isAdmin && {
        headerName: this.$t('settlement.pick'),
        cellRendererFramework: 'PickUnpickPlayerEvent',
        width: 85,
        pinned: 'right',
        cellRendererParams: {
          action: 'PICK',
          pickedUnpicked: this.redrawTableNode,
        },
      },
    ];
  }

  get OPERATOR_COLUMNS(): any {
    return [
      {
        headerName: this.$t('event.name'),
        field: 'name',
        width: 300,
        cellClass: 'pl-6',
        cellRendererFramework: 'SettlementEventName',
        cellRendererParams: {
          showSettlementNote: true,
          showSetTimeNow: true,
        },
        lockPosition: true,
      },
      {
        headerName: this.$t('event.code'),
        field: 'landbaseCode',
        width: 80,
        lockPosition: false,
      },
      {
        headerName: this.$t('settlement.playerName'),
        cellStyle: { display: 'flex', 'align-items': 'center', 'justify-content': 'center' },
        width: 110,
        field: 'playerName',
      },
      {
        headerComponent: 'SettlePlayerTableHeader',
        width: 420,
        cellRendererFramework: 'PlayerSettleByMarket',
        cellStyle: { padding: '0px' },
      },
      {
        headerName: this.$t('settlement.settlementNote'),
        width: 130,
        cellRendererFramework: 'ReactiveCell',
        cellRendererParams: {
          dataKey: 'settlementNote',
        },
      },

      // DEPRACATED -- NO TTX FOR NOW
      // {
      //   headerName: this.$t('settlement.ttx'),
      //   width: 65,
      //   cellRendererFramework: 'ActionCell',
      //   cellRendererParams: {
      //     clickFunc: 'onToggleTTX',
      //     buttonText: this.$t('settlement.ttx'),
      //     buttonType: 'primary',
      //     eventType: 'PLAYER',
      //   },
      // },
      {
        headerName: this.$t('settlement.remove'),
        width: 105,
        cellRendererFramework: 'PickUnpickPlayerEvent',
        cellRendererParams: {
          action: 'UNPICK',
          pickedUnpicked: this.redrawTable,
        },
      },
      {
        headerName: this.$t('settlement.auto'),
        width: 65,
        cellRendererFramework: 'PickXMLFeed',
        cellRendererParams: {
          eventType: 'PLAYER',
        },
      },
      {
        headerName: this.$t('settlement.ok'),
        width: 50,
        cellRendererFramework: 'ActionCell',
        cellRendererParams: {
          eventType: 'PLAYER',
          clickFunc: 'onVerify',
          buttonText: this.$t('settlement.ok'),
          buttonType: 'primary',
          showPopconfirm: true,
        },
      },
      {
        headerName: this.$t('settlement.bets'),
        width: 55,
        cellRendererFramework: 'ActionCell',
        cellRendererParams: {
          clickFunc: 'onMatchBets',
          buttonText: this.$t('settlement.bets'),
          buttonType: 'primary',
          eventType: 'PLAYER',
        },
      },
      {
        headerName: this.$t('settlement.notes'),
        cellRendererFramework: 'ActionCell',
        width: 55,
        cellRendererParams: {
          clickFunc: 'onOpenNote',
          onClick: () => this.toggleModal('settlementNotes'),
          buttonText: this.$t('settlement.notes'),
          buttonType: 'primary',
        },
      },
      {
        headerName: this.$t('settlement.oddOne'),
        cellRendererFramework: 'ActionCell',
        width: 45,
        cellRendererParams: {
          clickFunc: 'onSetAllOddsToOne',
          buttonText: this.$t('settlement.oddOne'),
          buttonType: 'primary',
          eventType: 'PLAYER',
        },
      },
      {
        headerName: this.$t('settlement.x'),
        flex: 1,
        cellRendererFramework: 'PublishUnpublishButton',
        cellRendererParams: {
          eventType: 'PLAYER',
          togglePlayerState: this.redrawTableNode,
        },
      },
      {
        cellRendererFramework: 'EventSlipsButton',
        width: 85,
        pinned: 'right',
        cellRendererParams: {
          isSettlement: true,
          eventType: 'P',
        },
      },
    ];
  }

  get isAdmin() {
    return AuthStore.isBackofficeAdmin;
  }

  get eventsRequest() {
    return SettlementStore.getPlayerEvents;
  }

  get sportsWithPlayers() {
    return SportStore.sportsWithPlayers;
  }

  get pickedPlayerEventIds() {
    return SettlementStore.pickedPlayerEventIds;
  }

  get stateChanged() {
    return SettlementStore.stateChanged;
  }

  @Watch('activeEventId')
  markEvent(val: number) {
    const isSelected = !!val;
    if (val) {
      this.selectedEventId = val;
    }
    this.markCurrentViewedEvent(isSelected);
  }

  @Watch('stateChanged')
  onStateChangeHandler() {
    // use this when BE returns ids of picked/unpicked Event Players
    // in store editEvent state based on ids returned
    // this.filterEvents();
  }

  @Watch('pickedPlayerEventIds')
  handlePlayerEventIds(eventIds: number[]) {
    eventIds.forEach(eventId => {
      this.redrawTableNode(eventId);
    });
  }

  async mounted() {
    await to(SettlementStore.getBackofficeUsers());
  }

  markCurrentViewedEvent(isSelected: boolean) {
    const rowNode = this.gridApi?.getRowNode(`${this.selectedEventId}`);
    if (rowNode && isSelected) {
      rowNode.setSelected(true);
    } else {
      rowNode?.setSelected(false);
    }
  }

  openPlayerMatchData(data: any) {
    SettlementStore.setActiveEvent(data.id);
    this.toggleModal('playerMatchData');
  }

  noteUpdated(eventId: number) {
    this.redrawTableNode(eventId);
  }

  redrawTableNode(eventId: number) {
    const foundEvent = SettlementStore.settlementEvents[`e_${eventId}`];
    this.gridApi?.forEachNode(node => {
      if (node.data.intKey === eventId) {
        node.setData(foundEvent);
      }
    });
  }

  redrawTable() {
    this.filterEvents();
  }

  close() {
    this.toggleModal('playerMatchData');
    SettlementStore.clearActiveEvent();
  }
}
