
import Vue from 'vue';
import Component from 'vue-class-component';
import { ICellRendererParams } from '@ag-grid-community/core';
import { SettlementStore } from '@/modules/settlement/settlement.store';
import to from 'await-to-js';
import { settlementService } from '@/modules/settlement/settlement.service';

interface PublishUnpublishButtonParams extends ICellRendererParams {
  eventType: 'DUAL' | 'PLAYER';
  sourceReq: string;
  togglePlayerState: (eventId: number) => number;
  toggleDualEventState: (eventId: number) => number;
}

@Component
export default class PublishUnpublishButton extends Vue {
  params: PublishUnpublishButtonParams | undefined;
  eventTime = 0;

  get hasData() {
    return !!this.params?.data;
  }

  get eventId() {
    if (!this.hasData) return '';
    return this.params?.data.id;
  }

  get eventState() {
    if (!this.hasData) return '';
    return this.params?.data.state;
  }

  get isDisabled() {
    return settlementService.isPublishUnpublishButtonDisabled(this.eventState);
  }

  get canPublish() {
    return settlementService.canEventBePublished(this.eventState);
  }

  get buttonType() {
    return this.eventState === 'PUBLISHED' ? 'danger' : 'primary';
  }

  get buttonText() {
    return this.eventState === 'PUBLISHED' ? 'X' : 'P';
  }

  get popconfirmTitle() {
    return this.eventState === 'PUBLISHED'
      ? this.$t('settlement.areYouSureUnpublish')
      : this.$t('settlement.areYouSurePublish');
  }

  errorMessage(description = this.$t('genericError.description')) {
    return this.$notification.error({
      message: this.$t('common.error') as string,
      description: description as string,
    });
  }

  successMessage(description: string) {
    return this.$notification.success({
      message: this.$t('common.success') as string,
      description: description,
    });
  }

  async onTogglePublish(nextState: 'PUBLISHED' | 'HIDDEN') {
    if (!this.params?.eventType) return;
    const [err] = await to<any>(
      SettlementStore.togglePublishEvent({
        events: [this.eventId],
        nextState: nextState,
        eventType: this.params?.eventType,
        source: this.params?.data.stage,
        sourceReq: this.params?.sourceReq ? this.params?.sourceReq : null,
      })
    );

    if (err) return this.errorMessage();
    if (this.params?.eventType === 'PLAYER') {
      this.params.togglePlayerState(this.eventId);
    }
    const successDescription =
      nextState === 'PUBLISHED'
        ? this.$t('settlement.publishSuccess')
        : this.$t('settlement.disableSuccess');
    this.successMessage(successDescription as string);
  }

  onClickHandler() {
    if (this.eventState === 'PUBLISHED') return this.onTogglePublish('HIDDEN');
    if (this.canPublish) return this.onTogglePublish('PUBLISHED');
    return this.errorMessage(this.$t('settlement.invalidStateChange'));
  }
}
